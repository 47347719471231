import { useState } from "react";

export const SubscriptionCard = ({ data }) => {
  const [activeCardId, setActiveCardId] = useState(null);
  const { planName, price, features, buttonText, id } = data;

  return (
    <>
      <div className="col-sm-6 col-md-4">
        <div
          className={`subscriptions-paid card border rounded-2 shadow-none ${
            activeCardId === id ? "active" : ""
          }`}
        >
          <div className="text-center py-3">
            <div className="border-bottom mb-3">
              <p className="m-0">{planName}</p>
              <h2 className="fw-bolder">
                {price}
                <sub className="fw-lighter">/month</sub>
              </h2>
            </div>
            <ul className="navbar-nav">
              {features.map((feature, index) => (
                <li className="nav-item mb-3" key={index}>
                  <span className="material-symbols-outlined fw-bold">
                    check_small
                  </span>
                  {feature}
                </li>
              ))}
            </ul>
            <div className="mx-4 text-center">
              <button
                type="button"
                data-bs-toggle="button"
                onClick={() => {
                  setActiveCardId(activeCardId === id ? null : id);
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
