import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import JobPost from "../pages/dashboard/jobpost";
import DashboardHome from "../pages/dashboard";
import Analytics from "../pages/dashboard/analytics";
import SignupForm from "../pages/dashboard/signup";
import Login from "../pages/dashboard/login";
import PrivateRoutes from "../utils/PrivateRoute";

import RedirectToHome from "../components/RedirectToHome.js";
import TermsAndConditions from "../pages/home/termsandconditions/index.js";
import PrivacyPolicy from "../pages/home/privacypolicy/index.js";
import AcceptableUsePolicy from "../pages/home/acceptableusepolicy/index.js";
import ManageJobs from "../pages/dashboard/manage-jobs/index.jsx";
import EditManagerJobs from "../pages/dashboard/edit-manager-jobs/index.jsx";
import Candidates from "../pages/dashboard/candidates/index.jsx";
import Reports from "../pages/dashboard/reports/index.jsx";
import ForgotPassword from "../pages/dashboard/forgot-Password/index.jsx";
import NewPassword from "../pages/dashboard/new-password/index.jsx";
import ScheduleInterview from "../pages/dashboard/scheduleInterview/index.jsx";
import SubscriptionsPlans from "../pages/dashboard/subscriptions/index.jsx";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="acceptable-use-policy" element={<AcceptableUsePolicy />} />
        <Route path="sign-up" element={<SignupForm />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="redirect" element={<RedirectToHome />} />
        <Route path="schedule-interview" element={<ScheduleInterview />} />
        <Route exact element={<PrivateRoutes />}>
          <Route path="dashboard" element={<DashboardHome />} />
          <Route path="jobs" element={<JobPost />} />
          <Route path="manage-jobs/edit" element={<EditManagerJobs />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="candidates" element={<Candidates />} />
          <Route path="manage-jobs" element={<ManageJobs />} />
          <Route path="reports" element={<Reports />} />
          <Route path="subscription" element={<SubscriptionsPlans />} />
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
