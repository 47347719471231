import { createSlice } from "@reduxjs/toolkit";
import {
  DeleteJobsThunk,
  jobPostThunk,
  updateJobPostAPIThunk,
} from "../thunk/jobPostThunk";

const initialState = {
  loading: false,
  jobs: {},
  manageJobs: [],
  error: "",
};

export const jobSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setManageJobs: (state, action) => {
      return {
        ...state,
        manageJobs: action.payload,
      };
    },
    resetJobs: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // jobPostThunk
    builder.addCase(jobPostThunk.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.jobs = {};
    });
    builder.addCase(jobPostThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
      state.error = "";
      console.log("Job posted successfully: ", action.payload);
    });
    builder.addCase(jobPostThunk.rejected, (state, action) => {
      state.loading = false;
      state.jobs = {};
      state.error = "Error occurred";
      console.log("Job post error: ", action.payload);
    });

    // updateJobPostAPIThunk
    builder.addCase(updateJobPostAPIThunk.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.jobs = {};
    });
    builder.addCase(updateJobPostAPIThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
      state.error = "";
      console.log("Update Jobs posted successfully: ", action.payload);
    });
    builder.addCase(updateJobPostAPIThunk.rejected, (state, action) => {
      state.loading = false;
      state.jobs = {};
      state.error = "Error occurred";
      console.log("Update Jobs post error: ", action.payload);
    });
    // DeleteJobsThunk
    builder.addCase(DeleteJobsThunk.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(DeleteJobsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      const filterJobs = Array.from(state.manageJobs);
      state.manageJobs = filterJobs.filter(
        (item) => item.job_id != action.payload?.id
      );
    });
    builder.addCase(DeleteJobsThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = "Error occurred";
      console.log("DeleteJobsThunk Jobs post error: ", action.payload);
    });
  },
});

export const jobAction = jobSlice.actions;
