import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { forgetPasswordAPI, signInAPI, signUpAPI } from "../../api/user";

export const signUpThunk = createAsyncThunk(
  "signUpThunk",
  async (data, thunkAPI) => {
    let signData = new FormData();
    signData.append("name", data.name);
    signData.append("phone", data.phone);
    signData.append("professional_email", data.professional_email);
    signData.append("company", data.company);
    signData.append("recruiters_count", data.recruiters_count);
    signData.append("timezone", data.timezone);
    signData.append("password", data.password);
    signData.append("re_password", data.re_password);
    try {
      const response = await signUpAPI(signData);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error.message);
      toast.error(error.response.data.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const signInThunk = createAsyncThunk(
  "signInThunk",
  async (data, thunkAPI) => {
    let signData = new FormData();
    signData.append("email_or_phone", data.email_or_phone);
    signData.append("password", data.password);
    try {
      const response = await signInAPI(signData);
      if (response.data?.token) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error.message);
      toast.error(error.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);

export const ForgetPasswordThunk = createAsyncThunk(
  "ForgetPasswordThunk",
  async (data, thunkAPI) => {
    let signData = new FormData();
    signData.append("email", data.email);
    try {
      const response = await forgetPasswordAPI(signData);
      if (
        response.data.message ==
        "Password reset link has been sent to your email."
      ) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error.message);
      toast.error(error.message);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);
