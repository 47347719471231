import { API } from "../Request";

export const jobPostAPI = async (data) =>
  await API.post("/match", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const AnalysisNotes = async (data) =>
  await API.post("/add_note", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const jobFetchDataAPI = async (data) =>
  await API.post("/fetch-data", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateJobPostAPI = async (data) =>
  await API.post("/resume_update", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const deleteJobsID = async (job_id) => {
  const formData = new FormData();
  formData.append("job_id", job_id);

  return await API.delete("/delete_analysis", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const jobApplyDataAPI = async (data) =>
  await API.post("/add_status", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
