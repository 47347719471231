const CounterDashboard = ({ count_No, counter_Name, counter_Image }) => {
  return (
    <div className="col-12 col-sm-6 col-md-4 position-relative">
      <div className="circle-badge">{count_No}</div>
      <div
        className="d-flex align-items-center p-3 h-100 rounded-4 py-5 w-100"
        style={{
          cursor: "pointer",
          fontSize: "12px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <span>{counter_Name}</span>
        <img
          src={counter_Image}
          alt="counter_Image"
          className="img-fluid"
          style={{ width: "75px", height: "auto" }}
        />
      </div>
    </div>
  );
};
export default CounterDashboard;
