import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DashboardLayouts from "../../../components/DashboardLayouts";
import "react-datepicker/dist/react-datepicker.css";
import { ScheduleEmailTemplatesID } from "../../../api/getJobPost";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { scheduleInterviewThunk } from "../../../lib/thunk/scheduleThunk";
import { baseUrl } from "../../../Request";

function InterviewInvite() {
  const getJobId = localStorage.getItem("jobID");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { schedule } = useSelector((state) => state.schedule);
  const { profileData, jobIdToUse, role } = location.state || {};
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};
  const [showModal, setShowModal] = useState(false);
  const [timeSlotsHide, setTimeSlotsHide] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [dashboarData, setdashboarData] = useState([]);
  const [attendeesNew, setAttendeesNew] = useState([]);
  const [formData, setFormData] = useState({
    interviewDate: null,
    interviewTime: null,
    interviewTimeEnd: null,
    timeSlotsSelectedStart: "",
    timeSlotsSelectedEnd: "",
    interviewer_Name: profileData?.Name || "",
    job_Profile: role || "",
    messageType: "",
    interviewVia: "",
    attendees: [profileData?.Email].filter((email) => email).join(","),
    subjectTemplate: "",
    mettingTemplate: "",
    sender_email: newuser.email,
    sender_name: profileData?.Name || "",
    Candidate_Id: profileData?.Candidate_Id || "",
    jobid: getJobId || jobIdToUse,
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      attendees: [profileData?.Email, ...attendeesNew]
        .filter((email) => email)
        .join(","),
    }));
  }, [attendeesNew, profileData?.Email]);

  const [formErrors, setFormErrors] = useState({
    interviewDate: "",
    interviewer_Name: "",
    job_Profile: "",
    interviewVia: "",
    messageType: "",
    attendees: "",
    subjectTemplate: "",
    mettingTemplate: "",
  });
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.interviewDate) {
      errors.interviewDate = "Interview date is required";
      isValid = false;
    }

    if (!formData.interviewer_Name) {
      errors.interviewer_Name = "Interviewer name is required";
      isValid = false;
    }

    if (!formData.job_Profile) {
      errors.job_Profile = "Job profile is required";
      isValid = false;
    }

    if (!formData.interviewVia) {
      errors.interviewVia = "Please select the interview mode";
      isValid = false;
    }

    if (!formData.messageType) {
      errors.messageType = "Please select the method to send interview details";
      isValid = false;
    }

    if (!formData.attendees) {
      errors.attendees = "Attendee email is required";
      isValid = false;
    }

    if (!formData.subjectTemplate) {
      errors.subjectTemplate = "Please select a subject template";
      isValid = false;
    }

    if (!formData.mettingTemplate) {
      errors.mettingTemplate = "Meeting template is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const formatInterviewDate = (date) => {
    if (!date) return "";
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const formatInterviewTime = (time) => {
    if (!time) return "";
    const hours = time.getHours();
    const minutes = time.getMinutes();
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`; // Format as HH:mm
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...formData,
      interviewDate: formatInterviewDate(formData.interviewDate),
      interviewTime: formatInterviewTime(formData.interviewTime),
      interviewTimeEnd: formData.interviewTime
        ? formatInterviewTime(
            new Date(
              formData.interviewTime.setHours(
                formData.interviewTime.getHours() + 1
              )
            )
          )
        : null,
    };
    setdashboarData(dataToSubmit);
    if (validateForm()) {
      dispatch(scheduleInterviewThunk(dataToSubmit));
    } else {
      console.log("Form is invalid, not submitting");
    }
  };

  useEffect(() => {
    if (profileData === undefined) {
      navigate("/job-list");
    } else if (schedule.authorization_url) {
      console.log("schedule.authorization_url");
      if (schedule.authorization_url) {
        window.location.href = schedule.authorization_url;
      }
    } else if (schedule.message === "Meeting Scheduled") {
      navigate("/dashboard");
    } else {
      return;
    }
  }, [schedule, navigate]);

  const getFechData = async () => {
    try {
      const emailTemplate = await ScheduleEmailTemplatesID(
        formData.subjectTemplate
      );
      setFormData((prevData) => ({
        ...prevData,
        mettingTemplate: emailTemplate.data.template,
      }));
    } catch (error) {
      console.log("error", error);
      toast.error(error);
    }
  };
  useEffect(() => {
    if (formData.subjectTemplate) {
      getFechData();
    }
  }, [formData.subjectTemplate]);

  function formatNewDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const timeSloat = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      date: formatNewDate(formData.interviewDate),
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseUrl}/get_time_recommendations`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTimeSlots(result.recommendations);
        toast.success("Time recommendations loaded");
      })
      .catch((error) => console.error(error));
  };
  const timeString = formData.timeSlotsSelectedStart;
  const currentDate = new Date();
  const [hours, minutes] = timeString.split(":");
  const newDate = currentDate.setHours(
    parseInt(hours),
    parseInt(minutes),
    0,
    0
  );

  return (
    <DashboardLayouts>
      <div
        style={{
          maxWidth: "600px",
          margin: "auto",
        }}
        className="shadow p-4 rounded-2 mb-4"
      >
        <h3 className="mb-4">Invite for Interview</h3>
        <div className="d-flex gap-2 mb-3">
          <button
            onClick={() =>
              setFormData({
                ...formData,
                interviewDate: new Date(),
              })
            }
            disabled={timeSlots.length}
            className="btn btn-primary"
          >
            Schedule Interview Now
          </button>
          <button
            onClick={() => setShowModal(!showModal)}
            className="btn btn-secondary"
          >
            Schedule Interview Later
          </button>
        </div>
        <div className="mb-3">
          <label className="form-label">Select Interview Date & Time</label>
          <div className="d-flex gap-2">
            <div>
              <DatePicker
                selected={formData.interviewDate}
                onChange={(date) => {
                  setFormData({ ...formData, interviewDate: date });
                }}
                minDate={new Date()}
                maxDate={new Date()}
                dateFormat="MMMM d, yyyy"
                className="form-control shadow-none"
                placeholderText="MMMM d, yyyy"
              />
              {formErrors.interviewDate && (
                <div className="error-message">{formErrors.interviewDate}</div>
              )}
            </div>
            <div>
              <DatePicker
                selected={formData.interviewTime || newDate}
                onChange={(time) => {
                  setFormData({ ...formData, interviewTime: time });
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                minTime={new Date()}
                maxTime={new Date().setHours(23, 59, 59, 999)}
                dateFormat="h:mm aa"
                className="form-control shadow-none"
                placeholderText="h:mm aa"
                disabled={timeSlots.length}
              />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <button
            className="btn btn-success w-100 mb-3"
            onClick={() => {
              setTimeSlotsHide(!timeSlotsHide);
              setTimeSlots([]);
              if (!timeSlotsHide) {
                timeSloat();
              }
              if (timeSlotsHide) {
                setFormData({ ...formData, timeSlotsSelectedStart: "" });
              }
            }}
            disabled={
              !formData.interviewDate || formData.interviewTime !== null
            }
          >
            {!timeSlotsHide
              ? "Get Time Recommendations"
              : "Hide Time Recommendations"}
          </button>
          <div
            className={`${
              !timeSlotsHide ? "d-none" : "d-flex justify-content-center gap-2"
            }`}
          >
            {timeSlots.map((slot, index) => (
              <button
                key={index}
                className={`${
                  formData.timeSlotsSelectedStart === slot.start
                    ? "btn btn-primary"
                    : "btn btn-success"
                }`}
                onClick={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    timeSlotsSelectedStart: slot.start,
                    timeSlotsSelectedEnd: slot.end,
                  }))
                }
              >
                {`${slot.start}-${slot.end}`}
              </button>
            ))}
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">Select Interviewer</label>
          <input
            type="text"
            placeholder="Interviewer Name (Email)"
            className="form-control shadow-none"
            name="interviewer_Name"
            value={formData.interviewer_Name}
            onChange={(e) =>
              setFormData({ ...formData, interviewer_Name: e.target.value })
            }
          />
          {formErrors.interviewer_Name && (
            <div className="error-message">{formErrors.interviewer_Name}</div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Job Profile</label>
          <input
            type="text"
            name="job_Profile"
            value={formData.job_Profile}
            onChange={(e) =>
              setFormData({ ...formData, job_Profile: e.target.value })
            }
            className="form-control shadow-none"
          />
          {formErrors.job_Profile && (
            <div className="error-message">{formErrors.job_Profile}</div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Interview Via</label>
          <select
            onChange={(e) =>
              setFormData({ ...formData, interviewVia: e.target.value })
            }
            name="interviewVia"
            value={formData.interviewVia}
            className="form-select shadow-none"
          >
            <option value="">Select Interview Via</option>
            <option value="google_meet">Gmeet</option>
            {/* <option value="google_meet/zoom">Zoom</option> */}
            {/* <option value="In-person">In-person</option> */}
          </select>
          {formErrors.interviewVia && (
            <div className="error-message">{formErrors.interviewVia}</div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Send Interview Details Via</label>
          <select
            name="messageType"
            value={formData.messageType}
            onChange={(e) =>
              setFormData({ ...formData, messageType: e.target.value })
            }
            className="form-select shadow-none"
          >
            <option value="">Select Send Interview</option>
            <option value="email">Email</option>
            {/* <option value="whatsapp">WhatsApp</option> */}
          </select>
          {formErrors.messageType && (
            <div className="error-message">{formErrors.messageType}</div>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">Sender Email</label>

          <input
            type="text"
            placeholder="Attendee Email"
            className="form-control shadow-none mb-2"
            value={formData.attendees} // Use the attendee value for the input
            name="attendees"
            onChange={(e) =>
              setFormData({ ...formData, attendees: e.target.value })
            }
          />
          {formErrors.attendees && (
            <div className="error-message">{formErrors.attendees}</div>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">Additional Attendees</label>
          {attendeesNew.map((emailAttendee, index) => (
            <input
              key={index}
              type="text"
              placeholder="Attendee Email"
              value={emailAttendee}
              name={`attendeesNew-${index}`}
              className="form-control shadow-none mb-2"
              onChange={(e) => {
                const updatedAttendees = [...attendeesNew];
                updatedAttendees[index] = e.target.value;
                setAttendeesNew(updatedAttendees);
              }}
            />
          ))}
          <button
            onClick={() => setAttendeesNew([...attendeesNew, ""])}
            className="bg-primary text-white border ms-2"
            style={{ cursor: "pointer" }}
          >
            Add
          </button>
        </div>
        <div className="mb-3">
          <label className="form-label">Select Subject Template</label>
          <select
            value={formData.subjectTemplate}
            onChange={(e) => {
              setFormData({ ...formData, subjectTemplate: e.target.value });
            }}
            name="subjectTemplate"
            className="form-select shadow-none"
          >
            <option value="">Select Template</option>
            <option value="1">Template 1</option>
            <option value="2">Template 2</option>
            <option value="3">Template 3</option>
          </select>
          {formErrors.subjectTemplate && (
            <div className="error-message">{formErrors.subjectTemplate}</div>
          )}
        </div>
        <div className="mb-3">
          <textarea
            rows="7"
            value={formData.mettingTemplate}
            onChange={(e) =>
              setFormData({ ...formData, mettingTemplate: e.target.value })
            }
            className="form-control shadow-none"
          />
          {formErrors.mettingTemplate && (
            <div className="error-message">{formErrors.mettingTemplate}</div>
          )}
        </div>

        <div>
          <button
            className="btn btn-primary w-100"
            onClick={handleSubmit}
            // disabled={isButtonDisabled}
          >
            Send
          </button>
        </div>

        {/* Modal for Date Picker */}
        {showModal && (
          <div className="date-picker-model">
            <div className="date-picker-body">
              <h5 className="mb-2">Select Date & Time</h5>
              <div className="d-flex gap-2 mb-3">
                <DatePicker
                  selected={formData.interviewDate}
                  onChange={(date) => {
                    setFormData({ ...formData, interviewDate: date });
                  }}
                  minDate={new Date()}
                  dateFormat="MMMM d, yyyy"
                  placeholderText="MMMM d, yyyy"
                  className="form-control shadow-none w-100"
                />

                <DatePicker
                  selected={formData.interviewTime}
                  onChange={(time) => {
                    setFormData({ ...formData, interviewTime: time });
                    setShowModal(!showModal);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  dateFormat="h:mm aa"
                  placeholderText="h:mm aa"
                  className="form-control shadow-none w-100"
                  disabled={timeSlots.length}
                />
              </div>
              <div className="text-end">
                <button
                  onClick={() => setShowModal(false)}
                  className="btn btn-danger me-3"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayouts>
  );
}

export default InterviewInvite;
