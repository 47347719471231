import { Element } from "react-scroll";

const TestimonialsCard = ({ reviews, ClientsName, position, image }) => {
  return (
    <>
      <Element name="Clients">
        <div className="testimonials mb-5">
          <div className="container">
            <div className="testimonials-card card h-100 shadow-sm border-1 rounded-3">
              <div className="row gx-0">
                <div className="col-12">
                  <div className="testimonials-content p-4 mx-4">
                    <p
                      // className="fw-light"
                      style={{ fontSize: "26px", fontWeight: "600" }}
                    >
                      {reviews}
                    </p>
                    <div className="d-flex align-items-center gap-2 mt-4">
                      <img
                        src={image}
                        alt="user"
                        style={{
                          width: "45px",
                          height: "45px",
                          objectFit: "fill",
                        }}
                        className="rounded-circle"
                      />
                      <div>
                        <h6 className="fw-bolder m-0">{ClientsName}</h6>
                        <span className="fw-light">{position}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};
export default TestimonialsCard;
