const Features = ({ img, title, content }) => {
  return (
    <>
      <div className="card rounded-4 border-0 text-center">
        <div className="text-center">
          <img src={img} className="sider-card-img" alt={img} />
        </div>
        <div className="card-body">
          <h5 className="card-title fw-semibold">{title}</h5>
          <p className="card-text fw-normal">{content}</p>
        </div>
      </div>
    </>
  );
};

export default Features;
