import { createSlice } from "@reduxjs/toolkit";
import { signUpThunk } from "../thunk/user";
import { signInThunk } from "../thunk/user";

const initialState = {
  loading: false,
  user: null,
  error: "",
  message: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state, action) => {
      return initialState;
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    resetMessage: (state, action) => {
      return {
        ...state,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUpThunk.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.message = "";
    });
    builder.addCase(signUpThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload?.message;
      state.error = "";
      console.log("user successfully: ", action.payload);
    });
    builder.addCase(signUpThunk.rejected, (state, action) => {
      state.loading = false;
      state.message = "";
      state.error = "Error occurred";
      console.log("user post error: ", action.payload);
    });

    builder.addCase(signInThunk.pending, (state, action) => {
      state.loading = true;
      state.error = "";
      state.user = null;
    });
    builder.addCase(signInThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload));
      console.log("login  successfully: ", action.payload);
    });
    builder.addCase(signInThunk.rejected, (state, action) => {
      state.loading = true;
      state.error = "";
      state.user = null;
    });
  },
});

export const userAction = userSlice.actions;
