import { Element, Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

import image1 from "../../../assets/images/Ellipse1.png";
import image2 from "../../../assets/images/Ellipse2.png";
import image3 from "../../../assets/images/Ellipse3.png";
import image4 from "../../../assets/images/Ellipse4.png";
import { useEffect } from "react";

const Banner = () => {
  useEffect(() => {
    const bannerSection = document.getElementById("Banner");
    if (bannerSection) {
      const topOffset =
        bannerSection.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <Element name="Banner" className="banner">
        <div className="banner-section" id="Banner">
          <div className="banner-bg-section">
            <div className="banner-top-content mb-3">
              <button>New</button>
              <Link
                className="d-flex align-items-center justify-content-center"
                to="Topbanner"
                smooth={true}
                duration={50}
                delay={50}
                spy={true}
                offset={-100}
                activeClass="activeClass"
                style={{ cursor: "pointer" }}
              >
                How Employo AI helps boost productivity
                <span className="material-symbols-outlined ms-2">
                  trending_flat
                </span>
              </Link>
            </div>
            <h1 className="mb-3">
              Boost Your Hiring <br />
              Process with Employo AI
            </h1>
            <p className="fw-normal mb-3">
              Revolutionize how you recruit with our AI-driven platform designed
              to find the best talent, fast.
            </p>
            <div className="d-block d-md-flex align-items-center justify-content-center gap-3 image-bottom mb-4">
              <div className="mb-2 mb-md-2">
                <img src={image1} alt="BannerSide" />
                <img src={image2} alt="BannerSide" />
                <img src={image3} alt="BannerSide" />
                <img src={image4} alt="BannerSide" />
              </div>
              <small className="fw-medium">
                Already trusted by 1.2k recruiters
              </small>
            </div>
            <div className="banner-btn-section d-flex gap-2">
              <Link
                className="btn-more p-2 text-decoration-none rounded-1 px-4 fw-semibold me-2"
                to="Features"
                smooth={true}
                duration={50}
                spy={true}
                offset={400}
                activeClass="activeClass"
                delay={50}
                style={{ cursor: "pointer" }}
              >
                Learn More
              </Link>
              <RouterLink
                className="d-flex align-items-center justify-content-center gap-2 btn-started p-2 text-decoration-none rounded-1 px-3 fw-semibold"
                to="/login"
              >
                Get Started
                <span className="material-symbols-outlined">north_east</span>
              </RouterLink>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};
export default Banner;
