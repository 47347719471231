import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getMeetingDetails } from "../../api/scheduleAPI";

export const UpcomingsMeetings = () => {
  const [Meetings, setMeetings] = useState([]);
  const [MeetingsError, setMeetingsError] = useState("");
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};

  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchMeetings = async (
    startDate = formatDate(new Date("1970-01-01")),
    endDate = formatDate(new Date("9999-12-31")),
    email = newuser.email
  ) => {
    try {
      const response = await getMeetingDetails(startDate, endDate, email);
      const categorizedMeetings = categorizeMeetings(response.data);
      setMeetings(categorizedMeetings);
    } catch (error) {
      setMeetingsError(error?.response?.data?.message);
      console.log("!!! fetchMeetings", error);
    }
  };
  useEffect(() => {
    fetchMeetings();
  }, []);

  const categorizeMeetings = (meetings) => {
    const now = new Date();
    const normalizedToday = new Date(now.setHours(0, 0, 0, 0));

    const yesterday = new Date(normalizedToday);
    yesterday.setDate(normalizedToday.getDate() - 1);

    const startOfWeek = new Date(normalizedToday);
    startOfWeek.setDate(normalizedToday.getDate() - normalizedToday.getDay());

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    // Categorize meetings
    return meetings.reduce(
      (categories, meeting) => {
        const meetingDate = new Date(
          new Date(meeting.date).setHours(0, 0, 0, 0)
        );

        // Check for "Today"
        if (meetingDate.getTime() === normalizedToday.getTime()) {
          categories.today.push(meeting);
        }
        // Check for "Yesterday"
        else if (meetingDate.getTime() === yesterday.getTime()) {
          categories.yesterday.push(meeting);
        }
        // Check for "This Week" (Sunday to Saturday)
        else if (meetingDate >= startOfWeek && meetingDate <= endOfWeek) {
          categories.thisWeek.push(meeting);
        }

        return categories;
      },
      {
        today: [],
        yesterday: [],
        thisWeek: [],
      }
    );
  };

  return (
    <>
      <div>
        <p className="mt-3 d-flex align-items-center gap-1 mb-2">
          Upcomings Meetings
          <span
            className="material-symbols-outlined"
            style={{ color: "#5d5fef" }}
          >
            event_upcoming
          </span>
        </p>
        <div>
          {MeetingsError && MeetingsError}
          {Object.keys(Meetings).map((category) => (
            <div key={category}>
              <h6
                className={`text-capitalize fw-semibold m-0 ${
                  Meetings[category].length > 0 ? "mb-2" : ""
                }`}
                style={{ fontSize: "14px" }}
              >
                {category}
              </h6>

              {Meetings[category] && Meetings[category].length > 0 ? (
                Meetings[category].map((interview, index) => (
                  <div className="metting-dashboard" key={index}>
                    <div
                      class="text-white p-2 mb-2 rounded-lg position-relative speech-bubble-right"
                      style={{
                        backgroundColor:
                          category === "today"
                            ? "#5d5fef"
                            : category === "yesterday"
                            ? "#f4812d"
                            : category === "thisWeek"
                            ? "#737791"
                            : "#000",
                      }}
                    >
                      <div>
                        <div className="m-0 d-flex align-items-center justify-content-between">
                          <p
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                            }}
                            className="mb-0"
                          >
                            {interview.candidate_name}
                          </p>
                          <p
                            class="text-white small text-end mb-0"
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                            }}
                          >
                            {interview.start_time} -{interview.end_time}
                          </p>
                        </div>
                        <div>
                          <Link
                            to={interview.join_link}
                            target="_"
                            style={{
                              fontSize: "11px",
                              color: "#fff",
                              textDecoration: "none",
                            }}
                          >
                            {interview.join_link}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{ color: "#666", fontSize: "12px" }}
                  className="py-2"
                >
                  No data found
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
