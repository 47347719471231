import DataTable from "react-data-table-component";
import DashboardLayouts from "../../../components/DashboardLayouts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteJobsThunk } from "../../../lib/thunk/jobPostThunk";
import { useDispatch, useSelector } from "react-redux";
import { jobAction } from "../../../lib/slice/jobSlice";
import { getJobPostID } from "../../../api/getJobPost";

const ManageJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};
  const { manageJobs = [] } = useSelector((state) => state.jobs);
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);
   console.log(manageJobs,'manageJobs');
   
  const columns = [
    {
      name: "Job Title",
      selector: (row) => row.job_title,
    },
    {
      name: "Experience Level",
      selector: (row) => row.experience_level,
    },
    {
      name: "Schedule",
      selector: (row) => row.schedule,
    },
    {
      name: "Timeline",
      selector: (row) => row.timeline,
    },
    {
      name: "Working Type",
      selector: (row) => row.working_type,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button className="btn btn-sm btn-primary me-2 py-0 px-1">
            <span
              class="material-symbols-outlined"
              onClick={() => handleShow(row)}
              style={{ fontSize: "16px", marginTop: "5px" }}
            >
              visibility
            </span>
          </button>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-sm btn-primary me-2 py-0 px-1"
          >
            <span
              style={{ fontSize: "16px", marginTop: "5px" }}
              className="material-symbols-outlined"
            >
              edit
            </span>
          </button>
          <button
            onClick={() => handleDelete(row)}
            className="btn btn-sm btn-danger py-0 px-1"
          >
            <span
              style={{ fontSize: "16px", marginTop: "5px" }}
              className="material-symbols-outlined"
            >
              delete
            </span>
          </button>
        </div>
      ),
    },
  ];

  const handleShow = (row) => {
    navigate("/analytics", { state: row.job_id });
  };
  const handleEdit = (row) => {
    console.log("Edit clicked", row);
    navigate("/manage-jobs/edit", { state: row.job_id });
  };

  const handleDelete = (row) => {
    dispatch(DeleteJobsThunk(row.job_id));
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getJobPostID(newuser.email);
      setLoading(false);
      const data = response.data.jobs;
      if (data) {
        dispatch(jobAction.setManageJobs(data));
      }
    } catch (error) {
      console.log("!!! analytics card", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <>
      <DashboardLayouts>
        <DataTable
          columns={columns}
          data={manageJobs}
          pagination={true}
          progressPending={loading}
          responsive={true}
          highlightOnHover={true}
          striped={true}
          progressComponent={
            <div className="loader">
              <div className="ring"></div>
              <span>loading...</span>
            </div>
          }
        />
      </DashboardLayouts>
    </>
  );
};
export default ManageJobs;
