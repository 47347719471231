import { Link } from "react-router-dom";
import employoLogo from "../../assets/images/employo-logo.png";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <>
      <div className="sidebar bg-white vh-100 shadow-sm position-sticky top-0 z-0 mb-3">
        <div className="d-flex flex-column flex-shrink-0 p-2">
          <Link className="navbar-brand text-center py-2 mb-3" to="/dashboard">
            <img src={employoLogo} alt={employoLogo} />
          </Link>
          <ul className="nav nav-pills flex-column mx-auto">
            <li>
              <Link
                to="/dashboard"
                className={`nav-link mb-3  ${
                  splitLocation[1] === "dashboard" ? "here" : ""
                }`}
                aria-current="page"
              >
                <span className="material-symbols-outlined">dashboard</span>
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/jobs"
                className={`nav-link mb-3  ${
                  splitLocation[1] === "jobs" ? "here" : ""
                }`}
              >
                <span className="material-symbols-outlined">work</span>
                Add Jobs
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mb-3  ${
                  splitLocation[1] === "job-list" ? "here" : ""
                }`}
                to="/job-list"
              >
                <span className="material-symbols-outlined">monitoring</span>
                Analysis
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mb-3  ${
                  splitLocation[1] === "manage-jobs" ? "here" : ""
                }`}
                to="/manage-jobs"
              >
                <span className="material-symbols-outlined">
                  manage_accounts
                </span>
                Manage Jobs
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mb-3  ${
                  splitLocation[1] === "candidates" ? "here" : ""
                }`}
                to="/candidates"
              >
                <span className="material-symbols-outlined">groups</span>
                Candidates
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link mb-3  ${
                  splitLocation[1] === "reports" ? "here" : ""
                }`}
                to="/reports"
              >
                <span className="material-symbols-outlined">report</span>
                Reports
              </Link>
            </li>

            <li>
              <Link
                className={`nav-link mb-3  ${
                  splitLocation[1] === "subscription" ? "here" : ""
                }`}
                to="/subscription"
              >
                <span className="material-symbols-outlined">subscriptions</span>
                Subscribe Plan
              </Link>
            </li>

            <li>
              <Link
                to="/manage-profile"
                className={`nav-link mb-3  ${
                  splitLocation[1] === "manage-profile" ? "here" : ""
                }`}
              >
                <span className="material-symbols-outlined">
                  manage_accounts
                </span>
                Manage Profile
              </Link>
            </li>

            <li>
              <Link
                to="/settings"
                className={`nav-link mb-3  ${
                  splitLocation[1] === "settings" ? "here" : ""
                }`}
              >
                <span className="material-symbols-outlined">settings</span>
                Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
