import DashboardLayouts from "../../../components/DashboardLayouts";
import { SubscriptionCard } from "../../../components/SubscriptionCard";
import { data } from "./data";

const SubscriptionsPlans = () => {
  return (
    <>
      <DashboardLayouts>
        <div className="subscriptions-plans p-3">
          <div className="line-plans mb-4">
            <p className="mb-2">subscription Plans </p>
          </div>
          <div className="subscriptions-card">
            <div className="container-fluid">
              <div className="row">
                {data.map((item) => (
                  <SubscriptionCard key={item.id} data={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayouts>
    </>
  );
};
export default SubscriptionsPlans;
