import { API } from "../Request";

export const getAnalysis = () => {
  return API.request({
    url: "get_analysis",
    method: "GET",
  });
};

export const getJobPostAPIID = (id) => {
  return API.request({
    url: `get_analysis_id/${id}`,
    method: "GET",
  });
};

// export const getJobPost = () => {
//   return API.request({
//     url: "get_jobs",
//     method: "GET",
//   });
// };
export const getEditMangerJobsID = (id) => {
  return API.request({
    url: `get_job/${id}`,
    method: "GET",
  });
};
export const getCandidateInfoID = (job_id) => {
  return API.request({
    url: `/get_candidate_info?job_id=${job_id}`,
    method: "GET",
  });
};

export const ScheduleEmailTemplatesID = (templateId) => {
  return API.request({
    url: `/get_email_template?templateId=${templateId}`,
    method: "GET",
  });
};

export const getJobPostID = (email) => {
  return API.request({
    url: `/get_all_jobs?email=${email}`,
    method: "GET",
  });
};
