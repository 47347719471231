import { Link } from "react-scroll";
import employoLogo from "../../assets/images/employo-logo.png";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import LinkedIn from "../../assets/images/LinkedIn.svg";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer">
        {/* <div className="footer-section p-4"> */}
        <div className=" p-4">
          <div className="container-fluid">
            <footer className="d-block d-md-flex justify-content-around">
              <div>
                <div className="" style={{ marginLeft: "-24px" }}>
                  <Link
                    className="navbar-brand"
                    to="Banner"
                    smooth={true}
                    duration={50}
                    spy={true}
                    offset={-100}
                    delay={50}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={employoLogo}
                      alt={employoLogo}
                      className="img-fluid mb-3"
                      style={{ width: "250px", height: "auto" }}
                    />
                  </Link>
                </div>
              </div>
              <div>
                <h5 className="fw-semibold mb-3">Company</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <Link
                      className="nav-link p-0 text-black fw-normal"
                      to="Features"
                      aria-current="page"
                      smooth={true}
                      duration={50}
                      spy={true}
                      offset={-100}
                      style={{ cursor: "pointer" }}
                      delay={50}
                    >
                      Features
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      className="nav-link p-0 text-black fw-normal"
                      to="Clients"
                      smooth={true}
                      duration={50}
                      spy={true}
                      offset={-100}
                      style={{ cursor: "pointer" }}
                      delay={50}
                    >
                      Clients
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      className="nav-link p-0 text-black fw-normal"
                      to="Reviews"
                      smooth={true}
                      duration={50}
                      delay={50}
                      spy={true}
                      offset={-100}
                      style={{ cursor: "pointer" }}
                    >
                      Reviews
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      className="nav-link p-0 text-black fw-normal"
                      to="FAQs"
                      smooth={true}
                      duration={50}
                      delay={50}
                      spy={true}
                      offset={-100}
                      style={{ cursor: "pointer" }}
                    >
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h5 className="fw-semibold mb-3">Terms of Service</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2" style={{ cursor: "pointer" }}>
                    <RouterLink
                      to="/terms-and-conditions"
                      target="_blank"
                      className=" nav-link p-0 text-black fw-normal"
                    >
                      Terms and conditions
                    </RouterLink>
                  </li>
                  <li className="nav-item mb-2" style={{ cursor: "pointer" }}>
                    <RouterLink
                      to="/privacy-policy"
                      target="_blank"
                      className="nav-link p-0 text-black fw-normal"
                    >
                      Privacy Policy
                    </RouterLink>
                  </li>
                  <li className="nav-item mb-2" style={{ cursor: "pointer" }}>
                    <RouterLink
                      to="/acceptable-use-policy"
                      target="_blank"
                      className="nav-link p-0 text-black fw-normal"
                    >
                      Acceptable Use Policy
                    </RouterLink>
                  </li>
                </ul>
              </div>
              <div>
                <div className="social-media">
                  <h5 className="fw-semibold mb-3">Social media</h5>
                  <div>
                    <RouterLink to="https://www.facebook.com/profile.php?id=61564475094419">
                      <img
                        src={Facebook}
                        alt="facebook"
                        className="img-fluid me-3"
                      />
                    </RouterLink>
                    <RouterLink to="https://www.instagram.com/employo.ai/">
                      <img
                        src={Instagram}
                        alt="instgram"
                        className="img-fluid me-3"
                      />
                    </RouterLink>
                    <RouterLink to="https://www.linkedin.com/company/employo-ai/">
                      <img
                        src={LinkedIn}
                        alt="twitter"
                        className="img-fluid me-3"
                      />
                    </RouterLink>
                    {/* <RouterLink to="#">
                      <img src={Twitter} alt="twitter" className="img-fluid" />
                    </RouterLink> */}
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
        <div className="footer-bg-bottom">EmployoAi Copyright © 2024</div>
      </div>
    </>
  );
};

export default Footer;
