import { Link } from "react-router-dom";
import talently from "../../../assets/images/Detailed-Candidate.png";

const Candidate = () => {
  return (
    <>
      <div className="summary-section mb-5">
        <div className="summary mb-5">
          <div className="d-block d-md-flex align-items-center justify-content-center">
            <div>
              <img
                src={talently}
                alt="framerusercontent"
                className="img-fluid"
              />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <div className="px-2 px-md-5">
                <div className="mb-5">
                  <h2 className="fw-bold">
                    Detailed Candidate Scoring and Analysis
                  </h2>
                  <p>Make Informed Hiring Decisions</p>
                </div>
                <ul className="p-0">
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Comprehensive candidate profiles with in-depth analysis.
                  </li>
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Performance predictions based on historical data.
                  </li>
                  <li className="mb-2 fw-normal d-flex align-baseline gap-3">
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                    Easy-to-understand scoring to compare top candidates.
                  </li>
                </ul>
                <div className="banner-btn-section mt-5">
                  <Link
                    className="btn-more p-2 text-decoration-none rounded-1 px-4 fw-semibold me-2"
                    to="/sign-up"
                    style={{ cursor: "pointer" }}
                  >
                    Try now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Candidate;
