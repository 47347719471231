import DashboardLayouts from "../../../components/DashboardLayouts";
import profile from "../../../assets/images/profile-image.png";
import { useState } from "react";

const ManagerProfile = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    about: "",
    image: null,
  });

  const [errors, setErrors] = useState({});

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, image: URL.createObjectURL(file) });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      if (!/^\d*$/.test(value)) return;
    }
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validate = () => {
    let tempErrors = {};

    if (!formData.firstName.trim())
      tempErrors.firstName = "First name is required";
    if (!formData.lastName.trim())
      tempErrors.lastName = "Last name is required";

    if (!formData.email.trim()) {
      tempErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      tempErrors.email = "Invalid email format";
    }

    if (!formData.phone.trim()) {
      tempErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      tempErrors.phone = "Phone number must be exactly 10 digits";
    }

    if (!formData.about.trim())
      tempErrors.about = "About section cannot be empty";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  // Handle Form Submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      alert("Form submitted successfully!");
    }
  };

  return (
    <DashboardLayouts>
      <div className="job-post-container manager-profile mb-5">
        <h3 className="mb-3 fw-bold">Manage Profile</h3>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="fw-semibold">Profile</h5>
            <p>View and update your account details</p>
          </div>
          <div>
            <button
              className="continue-outline py-2 px-3 d-flex justify-content-center align-items-center gap-2"
              onClick={handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
        <div className="border-profile mb-4"></div>
        <div className="manager-profile-form">
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  className="form-control shadow-none py-2 mt-2 form-border-color mb-2"
                  placeholder="Orla"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && (
                  <small className="text-danger">{errors.firstName}</small>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  className="form-control shadow-none py-2 mt-2 form-border-color mb-2"
                  placeholder="Flores"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                {errors.lastName && (
                  <small className="text-danger">{errors.lastName}</small>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label">Avatar</label>
              <div className="d-flex align-items-center gap-3">
                <img
                  src={
                    formData.image ||
                    profile ||
                    "https://via.placeholder.com/50"
                  }
                  alt="Avatar"
                  className="avatar"
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="d-none"
                  id="fileInput"
                />
                <button
                  className="shadow-none form-border-color rounded-1"
                  onClick={() => document.getElementById("fileInput").click()}
                  type="button"
                >
                  Choose
                </button>
                <div
                  onClick={() => setFormData({ ...formData, image: null })}
                  className="d-flex justify-content-center align-items-lg-center"
                >
                  <span className="material-symbols-outlined">delete</span>
                  Remove
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Email</label>
                <input
                  type="text"
                  name="email"
                  className="form-control shadow-none py-2 mt-2 form-border-color mb-2"
                  placeholder="orlaflores@riddleui.com"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </div>
              <div className="col-md-6">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control shadow-none py-2 mt-2 form-border-color mb-2"
                  placeholder="XXXX-XXXX"
                  value={formData.phone}
                  onChange={handleChange}
                  maxLength={10}
                />
                {errors.phone && (
                  <small className="text-danger">{errors.phone}</small>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <label className="form-label">About</label>
                <textarea
                  name="about"
                  className="form-control shadow-none py-2 mt-2 form-border-color mb-2"
                  placeholder="Write about yourself..."
                  rows="5"
                  value={formData.about}
                  onChange={handleChange}
                />
                {errors.about && (
                  <small className="text-danger">{errors.about}</small>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </DashboardLayouts>
  );
};

export default ManagerProfile;
