import React, { useState } from "react";
import DashboardLayouts from "../../../components/DashboardLayouts";

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.currentPassword)
      tempErrors.currentPassword = "Current Password is required";
    if (!formData.newPassword)
      tempErrors.newPassword = "New Passowrd is required";
    if (!formData.confirmNewPassword)
      tempErrors.confirmNewPassword = "Confirm New Passowrd is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("validate");
    }
  };
  return (
    <DashboardLayouts>
      <div className="job-post-container Setting-section mb-5">
        <div className="p-4">
          <h3 className="mb-4 fw-bold">Account Settings</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <div className="input-group">
                <input
                  type={showPassword.currentPassword ? "text" : "password"}
                  className="form-control shadow-none p-2"
                  placeholder="Current Password *"
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="input-group-text"
                  onClick={() =>
                    setShowPassword((prev) => ({
                      ...prev,
                      currentPassword: !prev.currentPassword,
                    }))
                  }
                >
                  {showPassword.currentPassword ? (
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  ) : (
                    <span className="material-symbols-outlined">
                      visibility_off
                    </span>
                  )}
                </button>
              </div>
              {errors.currentPassword && (
                <div className="error-message">{errors.currentPassword}</div>
              )}
            </div>
            <div className="mb-4">
              <div className="input-group">
                <input
                  type={showPassword.newPassword ? "text" : "password"}
                  className="form-control shadow-none p-2"
                  placeholder="New passowrd *"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="input-group-text"
                  onClick={() =>
                    setShowPassword((prev) => ({
                      ...prev,
                      newPassword: !prev.newPassword,
                    }))
                  }
                >
                  {showPassword.newPassword ? (
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  ) : (
                    <span className="material-symbols-outlined">
                      visibility_off
                    </span>
                  )}
                </button>
              </div>
              {errors.newPassword && (
                <div className="error-message">{errors.newPassword}</div>
              )}
            </div>
            <div className="mb-4">
              <div className="input-group">
                <input
                  type={showPassword.confirmNewPassword ? "text" : "password"}
                  className="form-control shadow-none p-2"
                  placeholder="Confirm New Password *"
                  name="confirmNewPassword"
                  value={formData.confirmNewPassword}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="input-group-text"
                  onClick={() =>
                    setShowPassword((prev) => ({
                      ...prev,
                      confirmNewPassword: !prev.confirmNewPassword,
                    }))
                  }
                >
                  {showPassword.confirmNewPassword ? (
                    <span className="material-symbols-outlined">
                      visibility
                    </span>
                  ) : (
                    <span className="material-symbols-outlined">
                      visibility_off
                    </span>
                  )}
                </button>
              </div>
              {errors.confirmNewPassword && (
                <div className="error-message">{errors.confirmNewPassword}</div>
              )}
            </div>
            {loading ? (
              <button
                type="submit"
                className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                style={{ background: "#5d5fef", border: 0 }}
                disabled
              >
                Save changes
                <div
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary w-100 fw-semibold py-2 mt-2"
                style={{ background: "#5d5fef", border: 0 }}
              >
                Save changes
              </button>
            )}
          </form>
        </div>
      </div>
    </DashboardLayouts>
  );
};

export default Settings;
