import { Outlet } from "react-router-dom";
import DashboardHeader from "../DashboardHeader";
import Sidebar from "../Sidebar";

const DashboardLayouts = ({ children }) => {
  return (
    <>
      <div className="dashboard-layouts position-relative">
        <div className="container-fluid p-0">
          <div className="row gx-0">
            <div className="col-sm-3 col-md-3 col-lg-2">
              <Sidebar />
            </div>
            {/* <div className="col-sm-6 col-md-9 col-lg-10" style={{backgroundColor:"#e5edf9",}}> */}
            <div className="col-sm-9 col-md-9 col-lg-10">
              <DashboardHeader />
              <div className="p-2">
                {children} <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardLayouts;
