import CounterDashboard from "../../components/counerDashboard";
import dashboard1 from "../../assets/images/dashboard1.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { baseUrl } from "../../Request";
import { useDispatch } from "react-redux";

export const CardDasboard = ({ s }) => {
    const dispatch = useDispatch();
  const [dataCandidates, setCandidates] = useState([]);
  const [counts, setCounts] = useState({});
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};

  const navigate = useNavigate();
  const validStatuses = [
    "Invited to Interview",
    "Invite to Interview",
    "Not Interested",
    "Shortlisted",
    "Confirmed Interview",
  ];    

  const getFilteredData = (counterName) => {
    const statusIndex = validStatuses.indexOf(counterName);
    return dataCandidates.filter(
      (candidate) => candidate.Status === validStatuses[statusIndex]
    );
  };

  // Candidates

  const fetchDataNew = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/all_candidates?email=${newuser.email}`,
        requestOptions
      );
      const result = await response.json();
      const scores = Array.from(result?.candidates);
      const candidates = scores.flatMap((item) => {
        return item?.scores?.map((subItem) => {
          return {
            job_id: item?.job_id,
            ...subItem,
          };
        });
      });

      setCandidates(candidates);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataNew();
  }, [setCandidates]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${baseUrl}/dashboard_counts?email=${newuser.email}`, requestOptions)
      .then((response) => response.json())
      .then((result) => setCounts(result))
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      <div className="mt-3 row p-2 row-gap-5">
        <CounterDashboard
          count_No={counts.JobPosted}
          counter_Name="Job Posted"
          counter_Image={dashboard1}
          onClick={() => {
            navigate("/manage-jobs");
          }}
        />
        <CounterDashboard
          count_No={counts.ShortlistedCandidate}
          counter_Name="Shortlisted Candidates"
          counter_Image={dashboard1}
          onClick={() => {
            navigate("/candidates", {
              state: getFilteredData("Shortlisted"),
            });
          }}
        />

        <CounterDashboard
          count_No={counts.ReportsScheduled}
          counter_Name="Reports scheduled"
          counter_Image={dashboard1}
          onClick={() => {
            navigate("/reports");
          }}
        />
        <CounterDashboard
          count_No={counts.InterviewScheduled}
          counter_Name="Interview Scheduled"
          counter_Image={dashboard1}
          onClick={() => {
            navigate("/candidates", {
              state: getFilteredData("Invite to Interview"),
            });
          }}
        />

        <CounterDashboard
          count_No={counts.JobInterviewPending}
          counter_Name="Job Interview Pending"
          counter_Image={dashboard1}
          onClick={() => {
            navigate("/candidates", {
              state: getFilteredData("Invited to Interview"),
            });
          }}
        />

        <CounterDashboard
          count_No={counts.JobInterviewConfirmed}
          counter_Name="Job Interview confirmed"
          counter_Image={dashboard1}
          onClick={() => {
            navigate("/candidates", {
              state: getFilteredData("Confirmed Interview"),
            });
          }}
        />
      </div>
    </>
  );
};
