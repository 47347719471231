import React from "react";

const Accordion = ({ title, content, isActive, onClick }) => {
  return (
    <div className={`${isActive ? "accordion-section" : "accordion-single"}`}>
      <div
        className={`${isActive ? "accordion-active" : "accordion-unactive"}`}
        onClick={onClick}
      >
        <div>{title}</div>
        <div>
          {isActive ? (
            <span className="material-symbols-outlined">keyboard_arrow_up</span>
          ) : (
            <span className="material-symbols-outlined">
              keyboard_arrow_down
            </span>
          )}
        </div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;
