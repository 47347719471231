import { configureStore } from "@reduxjs/toolkit";
import { jobSlice } from "./slice/jobSlice";
import { userSlice } from "./slice/userSlice";
import { scheduleSlice } from "./slice/scheduleSlice";

export const store = configureStore({
  reducer: {
    [jobSlice.name]: jobSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [scheduleSlice.name]: scheduleSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
