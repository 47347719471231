import { Link } from "react-scroll";
import employoLogo from "../../assets/images/employo-logo.png";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    setScrolled(window.scrollY > 50); // Adjust the value as needed
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <div className="header-bg position-relative" id="headerTop"> */}
      {/* <div className="header-bg position-sticky top-0" id="headerTop"> */}
      <nav
        className={`${
          scrolled ? "header shadow py-3" : "header-section py-3"
        } navbar navbar-expand-lg`}
      >
        <div className="container-fluid">
          <Link
            className="navbar-brand"
            to="Banner"
            smooth={true}
            duration={50}
            spy={true}
            offset={-100}
            delay={50}
            style={{ cursor: "pointer" }}
          >
            <img src={employoLogo} alt={employoLogo} className="img-fluid" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link fw-semibold"
                  to="Features"
                  aria-current="page"
                  smooth={true}
                  duration={50}
                  spy={true}
                  offset={-100}
                  activeClass="activeClass"
                  delay={50}
                >
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link fw-semibold"
                  to="Clients"
                  smooth={true}
                  duration={50}
                  spy={true}
                  offset={-120}
                  activeClass="activeClass"
                  delay={50}
                >
                  Clients Reviews
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link fw-semibold"
                  to="FAQs"
                  smooth={true}
                  duration={50}
                  delay={50}
                  spy={true}
                  offset={-100}
                  activeClass="activeClass"
                >
                  FAQs
                </Link>
              </li>
            </ul>
            <div>
              <RouterLink
                className="btn-get-started px-3 py-2 fw-semibold d-flex align-items-center justify-content-center gap-1"
                to="/login"
              >
                Login/signup
                <span className="material-symbols-outlined">north_east</span>
              </RouterLink>
            </div>
          </div>
        </div>
      </nav>
      {/* <Banner /> */}
      {/* </div> */}
    </>
  );
};

export default Header;
