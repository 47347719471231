import AnalyticsCard from "../../../components/AnalyticsCard";
import DashboardLayouts from "../../../components/DashboardLayouts";

const Analytics = () => {
  return (
    <>
      <DashboardLayouts>
        <div className="analytics-section">
          <div className="container">
            <div className="Analytics d-flex align-items-center justify-content-between"></div>
            <div className="Analytics-row">
              <div className="row">
                <AnalyticsCard />
              </div>
            </div>
          </div>
        </div>
      </DashboardLayouts>
    </>
  );
};
export default Analytics;
