import { useEffect, useState } from "react";
import { getJobPostAPIID } from "../../api/getJobPost";
import Pagination from "../Pagination";
import image1 from "../../assets/images/Logic_Data_Types.svg";
import image2 from "../../assets/images/Email.svg";
import image3 from "../../assets/images/Internship.svg";
import image4 from "../../assets/images/LinkedIn_card.svg";
import image5 from "../../assets/images/Education.svg";
import image6 from "../../assets/images/Bag.svg";
import image7 from "../../assets/images/Location.svg";
import image8 from "../../assets/images/Development_Skill.svg";
import image9 from "../../assets/images/Resume.svg";
import image10 from "../../assets/images/GitHub.svg";
import image11 from "../../assets/images/Behance.svg";
import Select from "react-select";

import Add from "../../assets/images/Add.svg";
import Minus from "../../assets/images/Minus.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AnalysisFetchDataThunk,
  AnalysisNotesThunk,
  JobAppliedThunk,
} from "../../lib/thunk/jobPostThunk";
import { useDispatch } from "react-redux";

const AnalyticsCard = () => {
  const getJobId = localStorage.getItem("jobID");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recordsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDetailsIndex, setShowDetailsIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ranking, setRanking] = useState(null);
  const [location, setLocation] = useState("");
  const [highestScore, setHighestScore] = useState(null);
  const [showAll, setShowAll] = useState(null);
  const [fetchData, setFetchData] = useState([]);
  const [notesGET, setNotesGET] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(false);
  const [selectedEdu, setSelectedEdu] = useState(false);
  const [gender, setGender] = useState({
    male: false,
    female: false,
    other: false,
  });

  const [experienceRanges, setExperienceRanges] = useState({
    fresher: false,
    "1-3": false,
    "3-5": false,
    "5-7": false,
    "7+": false,
  });
  const [notesCandidate, setNotesCandidate] = useState({
    email: "",
    jobid: getJobId,
    notes: "",
  });
  const locationID = useLocation();
  const manageJobsID = locationID.state;
  const jobIdToUse = manageJobsID || getJobId;

  const filterCandidates = () => {
    // Ensure fetchData or data is not undefined or null
    let filterData = [];
    if (selectedSkill && !selectedEdu) {
      const sortedBySkill = Object.values(fetchData).sort(
        (a, b) => b.Skill_Score - a.Skill_Score
      );
      filterData = Array.from(sortedBySkill);
    } else if (!selectedSkill && selectedEdu) {
      const sortedBySkill = Object.values(fetchData).sort(
        (a, b) => b.Education_Score - a.Education_Score
      );
      filterData = Array.from(sortedBySkill);
    } else {
      filterData = Array.from(data);
    }

    let filteredData = filterData.filter((profileData, index) => {
      // ranking
      if (typeof ranking === "number") {
        return profileData?.Overall_10 >= ranking;
      }
      if (location) {
        if (
          location &&
          !profileData.Location.toLowerCase().includes(location.toLowerCase())
        ) {
          return false;
        }
      }
      // selectedEducation
      if (selectedEducation.length > 0) {
        const selectedEducationValues = selectedEducation.map((edu) =>
          edu.value.toLowerCase()
        );
        const candidateEducation = profileData.Education.toLowerCase();

        const hasMatchingEducation = selectedEducationValues.some((edu) =>
          candidateEducation.includes(edu)
        );
        if (!hasMatchingEducation) {
          return false;
        }
      }
      // selectedSkills
      if (selectedSkills.length > 0) {
        const selectedSkillValues = selectedSkills.map((skill) =>
          skill.value.toLowerCase()
        );
        const candidateSkills = profileData.Skills.map((skill) =>
          skill.toLowerCase()
        );

        const hasAllSkills = selectedSkillValues.every((skill) =>
          candidateSkills.includes(skill)
        );
        if (!hasAllSkills) {
          return false;
        }
      }
      // selectedLocation
      if (selectedLocation.length > 0) {
        const selectedLocationValues = selectedLocation.map((loc) => {
          const [country, state] = loc.value.toLowerCase().split(" - ");
          return { country, state };
        });

        const candidateCountry = profileData.Country.toLowerCase();
        const candidateState = profileData.state.toLowerCase();

        const hasMatchingLocation = selectedLocationValues.some(
          (loc) =>
            loc.country === candidateCountry && loc.state === candidateState
        );

        if (!hasMatchingLocation) {
          return false; // Candidate does not match the selected locations
        }
      }

      // if (skills) {
      //   const requiredSkills = skills
      //     .split(",")
      //     .map((skill) => skill.trim().toLowerCase())
      //     .filter((skill) => skill.length > 0);
      //   const filteredItems = profileData.Skills.filter((item) => {
      //     return requiredSkills.some((skill) => {
      //       const itemName = String(item)
      //         .toLowerCase()
      //         .substring(0, skill.length);
      //       const isSame = itemName === skill;
      //       return isSame;
      //     });
      //   });
      //   if (filteredItems.length === 0) {
      //     return false;
      //   }
      // }
      // experienceRanges
      if (experienceRanges) {
        const candidateExperience = profileData.Experience;
        const noExperienceSelected = Object.values(experienceRanges).every(
          (checked) => !checked
        );
        if (noExperienceSelected) {
          return true;
        }
        if (
          (experienceRanges.fresher && candidateExperience === 0) ||
          (experienceRanges["1-3"] &&
            candidateExperience >= 1 &&
            candidateExperience <= 3) ||
          (experienceRanges["3-5"] &&
            candidateExperience > 3 &&
            candidateExperience <= 5) ||
          (experienceRanges["5-7"] &&
            candidateExperience > 5 &&
            candidateExperience <= 7) ||
          (experienceRanges["7+"] && candidateExperience > 7)
        ) {
          return true;
        }

        return false;
      }
      // profileGender
      const profileGender = profileData.Gender.toLowerCase();
      if (
        (gender.male && profileGender !== "male") ||
        (gender.female && profileGender !== "female") ||
        (gender.other && profileGender !== "other")
      ) {
        return false;
      }

      return true;
    });
    return filteredData;
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filterCandidates()
    .slice(indexOfFirstRecord, indexOfLastRecord)
    .map((item) => item);

  const skillsOptions = currentRecords.flatMap((record) =>
    record.Skills.map((skill) => ({ value: skill, label: skill }))
  );

  const educationOptions = currentRecords.map((record) => ({
    value: record.Education, // the actual value
    label: record.Education, // the label shown in the dropdown
  }));

  const locationOptions = currentRecords.map((record) => ({
    value: `${record.Country} - ${record.state}`, // the actual value
    label: `${record.Country} - ${record.state}`, // the label shown in the dropdown
  }));

  const handleEductionChange = (selectedOptions) => {
    setSelectedEducation([...selectedOptions] || []);
  };

  const handleSkillChange = (selectedOptions) => {
    setSelectedSkills(selectedOptions);
  };

  const handleLocationChange = (selectedOptions) => {
    setSelectedLocation(selectedOptions);
  };
  const nPages = Math.ceil(filterCandidates().length / recordsPerPage);

  const handleExperienceChange = (range) => {
    setExperienceRanges(() => {
      const newRanges = {
        fresher: false,
        "1-3": false,
        "3-5": false,
        "5-7": false,
        "7+": false,
      };
      newRanges[range] = true;
      return newRanges;
    });
  };

  useEffect(() => {
    const findHighestOverall10 = (data) => {
      let maxOverall10 = -Infinity;

      data.forEach((item) => {
        if (item.Overall_10 > maxOverall10) {
          maxOverall10 = item.Overall_10;
        }
      });
      return { maxOverall10 };
    };

    const result = findHighestOverall10(data);
    setHighestScore(result.maxOverall10);
  }, [data]);

  const adjustRank = (overallRank) => {
    const descendingArray = [];
    for (let i = highestScore; i >= 1; i--) {
      descendingArray.push(i);
    }
    return descendingArray[overallRank - 1];
  };

  const fetchDataHandler = async () => {
    if (jobIdToUse) {
      setLoading(true);
      try {
        const response = await getJobPostAPIID(jobIdToUse);
        setLoading(false);
        const data = response.data;
        if (data) {
          setData(data.scores);
        }
      } catch (error) {
        console.log("!!! analytics card", error);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchDataHandler(1);
  }, []);

  useEffect(() => {
    const bannerSection = document.getElementById("analtices_new");
    if (bannerSection) {
      const topOffset =
        bannerSection.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  }, []);

  const handleNotes = async () => {
    try {
      await dispatch(AnalysisNotesThunk(notesCandidate));
      setNotesCandidate({
        email: "",
        jobid: "",
        notes: "",
      });
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSkill) {
      newDataHanlder("skill");
    }
    if (selectedEdu) {
      newDataHanlder("education");
    }
  }, [selectedSkill, selectedEdu]);

  const newDataHanlder = async (type = "skill") => {
    try {
      const response = await dispatch(
        AnalysisFetchDataThunk({ type, job_id: jobIdToUse })
      );
      setFetchData(response.payload.scores);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const JobApplied = (selectedJob, profileData, status) => {
    const dataJob = {
      selectedJob,
      profileData,
      status,
      jobid: jobIdToUse,
    };
    navigate("/candidates", { state: profileData });
    dispatch(JobAppliedThunk(dataJob));
  };

  return (
    <>
      <div className="analtices-new-section" id="analtices_new">
        <div className="analtices-sec">
          <div className="row">
            <div className="col-sm-6 col-md-9">
              {loading ? (
                <div className="loader">
                  <div className="ring"></div>
                  <span>loading...</span>
                </div>
              ) : currentRecords.length > 0 ? (
                currentRecords.map((profileData, index) => (
                  <div
                    className="profile-card shadow-sm rounded border mb-3"
                    key={index}
                  >
                    <div className="p-2 px-3 d-flex justify-content-between align-items-center border-bottom">
                      <h4 className="profile-name mb-0">{profileData.Name}</h4>
                      <h4 className="profile-name mb-0">
                        {profileData.Overall_10 &&
                          `Rank - ${adjustRank(profileData.Overall_10)}`}
                      </h4>
                    </div>
                    <div className="p-2 px-3 border-bottom">
                      <div className="profile-scores mb-2">
                        <div className="row">
                          <div className="col">
                            <p className="score-value gap-1">
                              <img
                                src={image1}
                                alt="image1"
                                className="img-fluid"
                              />
                              Overall Score :
                              <span className="ms-1 text-dark">{`${profileData.Overall_10}/10`}</span>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <p className="score-value gap-1">
                              <img
                                src={image3}
                                alt="image1"
                                className="img-fluid"
                              />
                              Experience Score :
                              <span className="ms-1 text-dark">{`${
                                profileData.Experience_Score / 10
                              }/10`}</span>
                            </p>
                          </div>
                          <div className="col">
                            <p className="score-value">
                              <img
                                src={image9}
                                alt="image1"
                                className="img-fluid"
                              />
                              Skill Score :
                              {profileData.Skill_Score && (
                                <span className="ms-1 text-dark">{`${
                                  profileData.Skill_Score / 10
                                }/10`}</span>
                              )}
                            </p>
                          </div>
                          <div className="col">
                            <Link to={profileData.Links.LinkedIn_Links?.[0]}>
                              <img
                                src={image4}
                                alt="LinkedIn"
                                className="img-fluid"
                              />
                            </Link>
                            <Link to={profileData.Links.GitHub_Links?.[0]}>
                              <img
                                src={image10}
                                alt="GitHub_Links"
                                className="img-fluid"
                              />
                            </Link>
                            <Link to={profileData.Links.Behance_Links?.[0]}>
                              <img
                                src={image11}
                                alt="Behance_Links"
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="location-info mb-3">
                        <div className="row">
                          {profileData.Email && profileData.Email !== "N/A" && (
                            <div className="col">
                              <img
                                src={image2}
                                alt="email"
                                className="img-fluid"
                              />
                              <span>{profileData.Email}</span>
                            </div>
                          )}
                          {profileData.Location &&
                            profileData.Location !== "N/A" && (
                              <div className="col">
                                <img
                                  src={image7}
                                  alt="location"
                                  className="img-fluid"
                                />
                                <span>{`${profileData.Country} - ${profileData.state}`}</span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="contact-info mb-3">
                        <div className="row">
                          {profileData.Experience !== "N/A" && (
                            <div className="col">
                              <>
                                <img
                                  src={image6}
                                  alt="image6"
                                  className="img-fluid"
                                />
                                {/* {Number.isInteger(profileData.Experience)
                                  ? `${profileData.Experience} years`
                                  : `${profileData.Experience.toFixed(
                                      1
                                    )} years`} */}
                                {Number.isInteger(profileData.Experience)
                                  ? `${profileData.Experience} ${
                                      profileData.Experience === 1
                                        ? "year"
                                        : "years"
                                    }`
                                  : profileData.Experience < 1
                                  ? `${(profileData.Experience * 12).toFixed(
                                      0
                                    )} ${
                                      profileData.Experience * 12 === 1
                                        ? "month"
                                        : "months"
                                    }`
                                  : `${profileData.Experience.toFixed(
                                      1
                                    )} years`}
                              </>
                            </div>
                          )}
                          {profileData.Education &&
                            profileData.Education !== "N/A" && (
                              <div className="col">
                                <img
                                  src={image5}
                                  alt="Education"
                                  className="img-fluid"
                                />
                                <span style={{ textTransform: "capitalize" }}>
                                  {profileData.Education}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="d-flex align-items-center gap-1 skills-section">
                        <div className="d-flex align-items-md-baseline flex-wrap mt-2 position-relative w-100">
                          {profileData.Skills.length > 0 && (
                            <p className="score-value">
                              <img
                                src={image8}
                                alt="skills"
                                className="img-fluid"
                              />
                              Skills :
                            </p>
                          )}

                          {profileData.Skills.slice(
                            0,
                            showAll === index ? profileData.Skills.length : 4
                          ).map((skill, i) => (
                            <small
                              key={i}
                              className="badge bg-light me-2 mb-2 ms-2"
                            >
                              {skill}
                            </small>
                          ))}

                          {profileData.Skills.length > 4 && (
                            <button
                              onClick={() =>
                                setShowAll(showAll === index ? null : index)
                              }
                              className="btn btn-link p-0 text-decoration-none position-absolute"
                              style={{ right: "-12px" }}
                            >
                              {showAll === index ? (
                                <img
                                  src={Minus}
                                  alt="Minus"
                                  className="img-fluid"
                                />
                              ) : (
                                <img
                                  src={Add}
                                  alt="Add"
                                  className="img-fluid"
                                />
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="button-section d-flex justify-content-around py-3">
                      <button
                        className="know"
                        onClick={() =>
                          setShowDetailsIndex(
                            showDetailsIndex === index ? null : index
                          )
                        }
                      >
                        {showDetailsIndex === index
                          ? "Click to hide Summary"
                          : "Click to view Summary"}
                      </button>
                      <button
                        className="invite "
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          setNotesCandidate({
                            ...notesCandidate,
                            email: profileData?.Email,
                          });
                          setNotesGET({
                            ...notesGET,
                            notesGET: profileData.Note,
                          });
                        }}
                      >
                        Notes
                      </button>

                      <div className="dropdown">
                        <button
                          className="short btn   dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() =>
                                JobApplied("Shortlisted", profileData, "1")
                              }
                            >
                              Shortlist
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() =>
                                JobApplied("Not Interested", profileData, "2")
                              }
                            >
                              Not interested
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => {
                                JobApplied(
                                  "Invited to Interview",
                                  profileData,
                                  "3"
                                );
                                navigate("/schedule-interview", {
                                  state: { profileData },
                                });
                              }}
                            >
                              Invite to interview
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog  modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Candidate Name
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  if (notesCandidate.notes) {
                                    setNotesCandidate({
                                      email: "",
                                      jobid: "",
                                      notes: "",
                                    });
                                  }
                                }}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <label className="form-label">
                                Notes
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                type="text"
                                className="form-control shadow-none py-2 mt-2 form-border-color"
                                value={
                                  notesCandidate.notes || notesGET.notesGET
                                }
                                onChange={(e) =>
                                  setNotesCandidate({
                                    ...notesCandidate,
                                    notes: e.target.value,
                                  })
                                }
                                name="candidateName"
                                rows="5"
                              />
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={async () => {
                                  await handleNotes();
                                  if (notesCandidate.notes) {
                                    setNotesCandidate({
                                      email: "",
                                      jobid: "",
                                      notes: "",
                                    });
                                  }
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 1000);
                                }}
                                data-bs-dismiss={
                                  notesCandidate.notes ? "modal" : ""
                                }
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showDetailsIndex === index && (
                      <div className="p-3 skills-summary">
                        <h6>
                          <strong>Candidate Summary</strong>
                        </h6>
                        <div>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: profileData.Summary,
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>No data found.</p>
              )}
              {currentRecords.length > 0 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
            <div className="col-sm-6 col-md-3 border p-0 rounded h-100">
              <h4 className="profile-name mb-0 border-bottom p-2 text-center">
                Filters
              </h4>
              <div className="px-3 mb-2 py-1">
                <h6>Priority</h6>
                <div className="d-flex justify-content-between">
                  <div className="mb-4">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input shadow-none"
                        id="skills"
                        checked={selectedSkill}
                        onClick={() => {
                          setSelectedSkill(!selectedSkill);
                          setSelectedEdu(false);
                        }}
                      />
                      <label className="form-check-label" htmlFor={`skills`}>
                        Skills
                      </label>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input shadow-none"
                        id="education"
                        checked={selectedEdu}
                        onClick={() => {
                          setSelectedEdu(!selectedEdu);
                          setSelectedSkill(false);
                        }}
                      />
                      <label className="form-check-label" htmlFor={`education`}>
                        Education
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h6>Ranking</h6>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 9}
                          id="ranking10"
                          value={9}
                          onChange={(e) => setRanking(9)}
                        />
                        <label className="form-check-label" htmlFor="ranking10">
                          9 & Above
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 8}
                          id="ranking09"
                          value={8}
                          onChange={(e) => setRanking(8)}
                        />
                        <label className="form-check-label" htmlFor="ranking09">
                          8 & Above
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 7}
                          id="ranking08"
                          value={7}
                          onChange={(e) => setRanking(7)}
                        />
                        <label className="form-check-label" htmlFor="ranking08">
                          7 & Above
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 6}
                          id="ranking08"
                          value={6}
                          onChange={(e) => setRanking(6)}
                        />
                        <label className="form-check-label" htmlFor="ranking08">
                          6 & Above
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 5}
                          id="ranking08"
                          value={5}
                          onChange={(e) => setRanking(5)}
                        />
                        <label className="form-check-label" htmlFor="ranking08">
                          5 & Above
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input shadow-none"
                          type="checkbox"
                          name="ranking"
                          checked={ranking === 4}
                          id="ranking08"
                          value={4}
                          onChange={(e) => setRanking(4)}
                        />
                        <label className="form-check-label" htmlFor="ranking08">
                          4 & Above
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h6>Locations</h6>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={locationOptions}
                    value={selectedLocation}
                    onChange={handleLocationChange}
                    placeholder="Select Location"
                  />
                </div>
                <div className="mb-4">
                  <h6>Skills</h6>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={skillsOptions}
                    value={selectedSkills}
                    onChange={handleSkillChange}
                    placeholder="Select skills"
                  />
                </div>
                <div className="mb-4">
                  <h6>Education</h6>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={educationOptions}
                    value={selectedEducation}
                    onChange={handleEductionChange}
                    placeholder="Select Education"
                  />
                </div>

                <div className="mb-4">
                  <h6>Experience</h6>
                  <div className="d-flex justify-content-between">
                    <div>
                      {Object.keys(experienceRanges)
                        .slice(0, 3)
                        .map((range) => (
                          <div className="form-check" key={range}>
                            <input
                              className="form-check-input shadow-none"
                              type="checkbox"
                              checked={experienceRanges[range]}
                              onChange={() => handleExperienceChange(range)}
                              id={`experience-${range}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`experience-${range}`}
                            >
                              {range === "fresher"
                                ? "Fresher"
                                : `${range} years`}
                            </label>
                          </div>
                        ))}
                    </div>
                    <div>
                      {Object.keys(experienceRanges)
                        .slice(3)
                        .map((range) => (
                          <div className="form-check" key={range}>
                            <input
                              className="form-check-input shadow-none"
                              type="checkbox"
                              checked={experienceRanges[range]}
                              onChange={() => handleExperienceChange(range)}
                              id={`experience-${range}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`experience-${range}`}
                            >
                              {range === "7+" ? "7+ years" : `${range} years`}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="button-section text-center fw-semibold">
                  <button
                    className="invite"
                    onClick={() => {
                      setRanking(null);
                      setLocation("");
                      setSelectedSkills([]);
                      setSelectedEducation([]);
                      setSelectedSkill("");
                      setSelectedEdu("");
                      setExperienceRanges({
                        fresher: false,
                        "1-3": false,
                        "3-5": false,
                        "5-7": false,
                        "7+": false,
                      });
                      setGender({ male: false, female: false, other: false });
                    }}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AnalyticsCard;
