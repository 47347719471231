import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { scheduleInterviewAPI } from "../../api/scheduleAPI";

export const scheduleInterviewThunk = createAsyncThunk(
  "scheduleInterviewThunk",
  async (data, thunkAPI) => {
    const formdata = new FormData();
    formdata.append("candidate_name", data.interviewer_Name);
    formdata.append("role", data.job_Profile);
    formdata.append("sender_position", data.job_Profile);
    formdata.append(
      "start_time",
      data.interviewTime || data.timeSlotsSelectedStart
    );
    formdata.append(
      "end_time",
      data.interviewTimeEnd || data.timeSlotsSelectedEnd
    );
    formdata.append("date", data.interviewDate);
    formdata.append("platform", data.interviewVia);
    formdata.append("attendees", data.attendees);
    formdata.append("email_preview", data.mettingTemplate);
    formdata.append("sender_email", data.sender_email);
    formdata.append("sender_name", data.sender_name);
    formdata.append("subject", "testing");
    formdata.append("Candidate_Id", data.Candidate_Id);
    formdata.append("jobid", data.jobid);

    try {
      const response = await scheduleInterviewAPI(formdata);
      if (response.data && response.data.authorization_url) {
        window.location.href = response.data.authorization_url; // Corrected this line
      }
      return response.data;
    } catch (error) {
      toast.error(error.message || "An error occurred");
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error
      );
    }
  }
);
