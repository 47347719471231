import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RedirectToHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get("message");
    const name = urlParams.get("name");
    const email = urlParams.get("email");
    const picture = urlParams.get("picture");
    const token = urlParams.get("token");

    const userData = {
      message: message,
      name: name,
      email: email,
      picture: picture,
      token: token,
    };
    toast.success("Google login successful!");

    setTimeout(() => {
      navigate("/dashboard");
      localStorage.setItem("googleToken", userData.token);
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("user", JSON.stringify(userData));
    }, 0);
  }, [navigate]);

  return (
    <div>
      <h1>Authentication Successful</h1>
      <p>Redirecting to the home page...</p>
    </div>
  );
};

export default RedirectToHome;
