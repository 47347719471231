export const data = [
  {
    id: 1,
    planName: "Free",
    price: "Free",
    features: [
      "Job Portal",
      "3 Active Job Postings",
      "Employee Referral",
      "Multi Location",
      "Lorem ipsum dolor sit",
      "Lorem ipsum dolor sit",
    ],
    buttonText: "Subscribed",
  },
  {
    id: 2,
    planName: "Pro",
    price: "299 INR",
    features: [
      "Job Portal",
      "3 Active Job Postings",
      "Employee Referral",
      "Multi Location",
      "Lorem ipsum dolor sit",
      "Lorem ipsum dolor sit",
    ],
    buttonText: "Subscribe",
  },
  {
    id: 3,
    planName: "Elite",
    price: "599 INR",
    features: [
      "Job Portal",
      "3 Active Job Postings",
      "Employee Referral",
      "Multi Location",
      "Lorem ipsum dolor sit",
      "Lorem ipsum dolor sit",
    ],
    buttonText: "Subscribe",
  },
];
