import DataTable from "react-data-table-component";
import DashboardLayouts from "../../../components/DashboardLayouts";
import { useState } from "react";

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const data = [
    {
      job_title: "Software Engineer",
      experience_level: "Mid-Level",
      schedule: "Full-time",
      timeline: "Immediate",
      working_type: "Remote",
    },
    {
      job_title: "SEO Specialist",
      experience_level: "Entry-Level",
      schedule: "Part-time",
      timeline: "Within 2 weeks",
      working_type: "Hybrid",
    },
    {
      job_title: "Product Manager",
      experience_level: "Senior",
      schedule: "Full-time",
      timeline: "Within 1 month",
      working_type: "On-site",
    },
    {
      job_title: "UI/UX Designer",
      experience_level: "Mid-Level",
      schedule: "Contract",
      timeline: "Immediate",
      working_type: "Remote",
    },
    {
      job_title: "Marketing Executive",
      experience_level: "Entry-Level",
      schedule: "Full-time",
      timeline: "Within 3 weeks",
      working_type: "Hybrid",
    },
  ];

  const columns = [
    {
      name: "Job Title",
      selector: (row) => row.job_title,
    },
    {
      name: "Experience Level",
      selector: (row) => row.experience_level,
    },
    {
      name: "Schedule",
      selector: (row) => row.schedule,
    },
    {
      name: "Timeline",
      selector: (row) => row.timeline,
    },
    {
      name: "Working Type",
      selector: (row) => row.working_type,
    },
  ];
  return (
    <>
      <div className="candidates">
        <DashboardLayouts>
          <DataTable
            columns={columns}
            data={data}
            pagination={true}
            progressPending={loading}
            responsive={true}
            highlightOnHover={true}
            striped={true}
          />
        </DashboardLayouts>
      </div>
    </>
  );
};
export default Reports;
