import React from "react";
import { Outlet, Navigate } from "react-router-dom";

export default function PrivateRoutes() {
  const userid = localStorage.getItem("token") == null ? false : true;
  const googleTokenID =
    localStorage.getItem("googleToken") == null ? false : true;

  return (
    <>{userid || googleTokenID ? <Outlet /> : <Navigate to="/login" />};</>
  );
}
