import React from 'react'
import Layouts from '../../../components/Layouts'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
  return (
    < >
      <Layouts>
        <section className="section">
          <div className="container">
            <div className="row">
              <div id="primary" className="content-area   mx-auto">
                <div id="content" className="site-content" role="main">
                  <article
                    id="post-108"
                    className="post-108 page type-page status-publish hentry p-5
                    "
                  >
                    <header className="entry-header">
                      <h1 className="entry-title fw-bold mb-3">
                        Privacy Policy
                      </h1>
                    </header>

                    <h2>General</h2>

                    <div className="entry-content">
                      <p className="text-justify fw-normal">
                      By using, accessing, or participating in Employo AI (the "Service"), you agree to the terms of this Privacy Policy. Capitalized terms not defined in this Privacy Policy have the meanings set forth in the Terms and Conditions, located here. We reserve the right to modify this Privacy Policy at any time. If changes are made, we will post a notice on the website for at least 7 days, and the effective date of the latest revision will be indicated at the bottom of this policy. The changes will take effect either after 7 days or upon your first use of the Service after the update. If you do not agree with this Privacy Policy, you are not authorized to use the Service.
                      </p>
                      <ol>
                          <p >Information We Collect</p>
                          <p style={{marginBottom: "0px"}}>When you use the Service, we collect two types of information:</p>
                        <li style={{marginBottom:"15px"}}>
                          <p style={{marginBottom:"0px"}}>Information you provide</p>
                          <ol className='fw-normal'>
                          <p style={{ marginBottom: "0px" }} className="text-justify">
                          This includes personal details like your name, email address, mobile number, and other information during registration. We use this information to create and manage your account.
                            </p>
                          </ol>
                        </li>
                        <li>
                          <p style={{marginBottom:"0px"}}>Information collected through interaction</p>
                          <p style={{ marginBottom: "0px" }} className="text-justify fw-normal">
                          We gather details about your use of the Service, such as your browser type, IP address, and other session information. This is done through cookies that help improve your experience. You can disable cookies in your browser settings, but some features may not work without them.
                          </p>
                        </li>
                        <p>
                        Employo AI also collects email addresses as part of enhanced security measures to protect your account and ensure a safer user experience.
                        </p>
                        <li style={{marginBottom:"15px"}}>
                          <p style={{marginBottom:"0px"}}>Use of Information</p>
                          <ol className="text-justify fw-normal">
                            <li className="text-justify">
                            The information we collect is used to provide you with the Service, offer personalized features, and improve the functionality of Employo AI. We may also use your contact information to notify you of new features, updates, or services that may be relevant to you. You may opt out of non-essential communications, though important account-related notices may still be sent.
                            </li>
                            <li className="text-justify">
                            Employo AI may also use aggregate or anonymized data for analytical purposes and improvement of services.
                            </li>
                          </ol>
                        </li>
                        <li  style={{marginBottom:"15px"}}>
                          <p style={{marginBottom:"0px"}}>Sharing Your Information</p>
                          <ol>
                          <p style={{ marginBottom: "0px" }} className="text-justify">
                          Employo only shares your personally identifiable information under limited circumstances
                            </p>
                            <ol >
                                <li>
                                <p style={{marginBottom:"0px"}}>Service Providers</p>
                                <p style={{marginBottom:"0px"}} className="text-justify fw-normal">We may share information with third-party service providers who assist us in delivering the Service. These providers are bound by agreements to protect your information and only use it for the purpose of providing their services.</p>
                                </li>
                                <li>
                                <p style={{marginBottom:"0px"}}>SDKs and Notifications</p>
                                <p className="text-justify fw-normal">We use third-party SDKs to deliver push notifications and other services. Depending on your device permissions, this data may include personal identifiers and device information, which could be used to customize content or ads.</p>
                                </li>
                                <p>We may disclose personally identifiable information if required by law or if necessary to protect our rights or comply with legal obligations.</p>
                            </ol>
                          </ol>
                        </li>
                        <li style={{marginBottom:"15px"}}>
                          <p style={{marginBottom:"0px"}}>Consumer Control & Opt-Out Options</p>
                          <p style={{ marginBottom: "0px" }} className="text-justify fw-normal">
                            You can control push notifications and cross-app advertising settings by adjusting your device preferences. For example, on iOS or Android, you can opt out of interest-based ads through your device's settings.</p>
                        </li>
                        <li style={{marginBottom:"15px"}}>
                          <p style={{marginBottom:"0px"}}>Security</p>
                          <ol>
                          <p style={{ marginBottom: "0px" }} className="text-justify fw-normal">
                             We take reasonable steps to ensure the security of your personal information. However, no security measure is entirely foolproof, and we cannot guarantee the complete security of your data.
                            </p>
                          </ol>
                        </li>
                        <li style={{marginBottom:"15px"}}>
                          <p style={{marginBottom:"0px"}}>Use of Third-Party Services</p>
                          <ol>
                          <p style={{ marginBottom: "0px" }} className="text-justify fw-normal">
                          Employo AI uses third-party analytics and communication tools like Intercom to help us understand and improve your usage of the Service. These services may collect some personal information such as email addresses and may use cookies to enhance user experience.
                            </p>
                          </ol>
                        </li>
                        <li style={{marginBottom:"15px"}}>
                          <p style={{marginBottom:"0px"}}>Links to Other Websites</p>
                          <ol>
                          <p style={{ marginBottom: "0px" }} className="text-justify fw-normal">
                          Employo AI may include links to other websites. We are not responsible for the privacy practices of external websites. We encourage users to review the privacy policies of those sites before sharing any personal information.
                            </p>
                          </ol>
                        </li>
                        <li style={{marginBottom:"15px"}}>
                          <p style={{marginBottom:"0px"}}>Changes to Ownership</p>
                          <ol>
                          <p style={{ marginBottom: "0px" }} className="text-justify fw-normal">
                          If the ownership of Employo AI changes, your personal information may be transferred to the new owner. The new owner will continue to be bound by this Privacy Policy.
                            </p>
                          </ol>
                        </li>
                        <li>
                          <p style={{marginBottom:"0px"}}>Contact Us</p>
                          <ol>
                          <p style={{ marginBottom: "0px" }} className="text-justify fw-normal" >
                          If you have any questions regarding this Privacy Policy, please contact us at <Link> support@employo.co.in</Link>
                            </p>
                          </ol>
                        </li>
                        
                      </ol>
                          <p>Last revised on October 10th, 2024</p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layouts>
    </>
  )
}

export default PrivacyPolicy
