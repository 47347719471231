export const data = [
  {
    id: 1,
    planName: "Free",
    price: "$0.00",
    features: [
      "Job Portal",
      "3 Active Job Postings",
      "Employee Referral",
      "Multi Location",
      "Lorem ipsum dolor sit",
      "Lorem ipsum dolor sit",
    ],
    buttonText: "Subscribed",
  },
  {
    id: 2,
    planName: "Basic",
    price: "$15.00",
    features: [
      "Job Portal",
      "3 Active Job Postings",
      "Employee Referral",
      "Multi Location",
      "Lorem ipsum dolor sit",
      "Lorem ipsum dolor sit",
    ],
    buttonText: "Subscribe",
  },
  {
    id: 3,
    planName: "Business",
    price: "$55.00",
    features: [
      "Job Portal",
      "3 Active Job Postings",
      "Employee Referral",
      "Multi Location",
      "Lorem ipsum dolor sit",
      "Lorem ipsum dolor sit",
    ],
    buttonText: "Subscribe",
  },
  {
    id: 4,
    planName: "Premium",
    price: "$65.00",
    features: [
      "Job Portal",
      "3 Active Job Postings",
      "Employee Referral",
      "Multi Location",
      "Lorem ipsum dolor sit",
      "Lorem ipsum dolor sit",
    ],
    buttonText: "Subscribe",
  },
];
