import React from "react";
import Layouts from "../../../components/Layouts";

const TermsAndConditions = () => {
  return (
    <>
      <Layouts>
        <section className="section">
          <div className="container">
            <div className="row">
              <div id="primary" className="content-area   mx-auto">
                <div id="content" className="site-content" role="main">
                  <article
                    id="post-108"
                    className="post-108 page type-page status-publish hentry p-5
                    "
                  >
                    <header className="entry-header">
                      <h1 className="fw-bold mb-3">
                        Terms And Conditions - General
                      </h1>
                    </header>

                    <div className="entry-content">
                      <p className="text-justify fw-normal">
                        The Employo AI website ("Website") and related services
                        (together with the Website, the "Service") are operated
                        by Wellorgs Infotech Pvt. Ltd. ("Employo AI", "us" or
                        "we"). Access to and use of the Service is subject to
                        the following Terms and Conditions ("Terms and
                        Conditions"). By accessing or using any part of the
                        Service, you agree that you have read, understood, and
                        accept to be bound by these Terms and Conditions,
                        including any future modifications. Employo AI may amend
                        or update these Terms and Conditions at any time, and if
                        so, we will notify you by posting a notice on the
                        Website for at least 7 days. The changes will take
                        effect either (i) at the end of the 7-day period or (ii)
                        the next time you access or use the Service after such
                        modifications. If you do not agree to abide by these
                        Terms and Conditions, you are not authorized to use or
                        access the Service.
                      </p>
                      <ol>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>
                            {" "}
                            Description of Website and Service
                          </p>
                          <ol>
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify fw-normal"
                            >
                              The Employo AI Service enables employers to
                              streamline the hiring process by providing
                              advanced tools for resume analysis, candidate
                              shortlisting, and interview scheduling. The
                              service includes (a) AI-driven resume scoring (b)
                              detailed candidate reports (c) automated interview
                              scheduling and other hiring support
                              functionalities. Employo AI reserves the right to
                              update, change, or discontinue any part of the
                              Service at its sole discretion.
                            </p>
                          </ol>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0px" }}>Registration</p>
                          <p
                            style={{ marginBottom: "0px" }}
                            className="fw-normal"
                          >
                            By registering to use the Employo AI Service, you
                            agree to :
                          </p>
                          <ol className="fw-normal">
                            <li>
                              Provide accurate, current, and complete
                              information during registration.
                            </li>
                            <li>
                              Maintain the confidentiality of your login
                              credentials.
                            </li>
                            <li>
                              Promptly update any changes to your registration
                              details.
                            </li>
                            <li>
                              Be responsible for all actions taken under your
                              account.
                            </li>
                          </ol>
                        </li>
                        <p className="fw-normal">
                          By registering, you also consent to be contacted by
                          Employo AI via phone or SMS regarding our services,
                          including promotional offers. Even if you are
                          registered under DND/DNC/NCPR services, you authorize
                          Employo AI to contact you for these purposes.
                        </p>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>Subscriptions</p>
                          <o className="fw-normal">
                            <li className="text-justify">
                              Employo AI offers monthly or annual subscription
                              plans for using its services. Payments may be
                              processed by third parties or directly through the
                              Employo AI platform. Subscription fees are listed
                              on the Website and may be subject to change. All
                              subscription payments are non-refundable, and
                              subscriptions will auto-renew unless canceled as
                              per the Terms of Service.
                            </li>
                            <li className="text-justify">
                              Free Trial : Employo AI may offer a free trial for
                              new users. Free trials automatically renew into
                              paid subscriptions unless canceled before the
                              trial period ends.
                            </li>
                            <li className="text-justify">
                              Automatic Renewal : Paid subscriptions auto-renew
                              at the stated subscription rate unless canceled at
                              least 24 hours before the end of the billing
                              period.
                            </li>
                          </o>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>
                            User Representations and Warranties
                          </p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              You represent and warrant that your use of the
                              Employo AI Service will comply with all applicable
                              laws and regulations. You also confirm that any
                              material or data you submit is either owned by you
                              or you have the right to grant a license to
                              Employo AI for use.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>
                            Inappropriate Use
                          </p>
                          <p style={{ marginBottom: "0px" }}>
                            You agree not to upload or share content through
                            Employo AI that{" "}
                          </p>
                          <ol className="fw-normal">
                            <li>
                              Is defamatory, abusive, harassing, or violates any
                              law.
                            </li>
                            <li>
                              Infringes on the rights of any third party.
                              Employo AI reserves the right to remove any
                              content deemed objectionable.
                            </li>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>Indemnification</p>
                          <ol>
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify fw-normal"
                            >
                              You agree to indemnify and hold Employo AI and its
                              affiliates harmless from any claims, damages, or
                              losses arising from your use of the Service or
                              breach of these Terms.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>
                            No Representations or Warranties
                          </p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              The Employo AI Service is provided "as is,"
                              without any warranty, either express or implied.
                              Employo AI disclaims any liability for errors,
                              disruptions, or losses of data.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>
                            Limitation of Liability
                          </p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              In no event will Employo AI be liable for any
                              indirect or consequential damages arising from the
                              use or inability to use the Service. The total
                              liability of Employo AI is limited to the amount
                              you paid for the Service in the 12 months prior to
                              the claim.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>Termination</p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              Employo AI reserves the right to terminate your
                              access to the Service at any time without notice.
                              You may terminate your account at any time by
                              following the instructions on the Website. Certain
                              provisions of these Terms, such as indemnity and
                              limitation of liability, will survive termination.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>
                            Proprietary Rights and Data
                          </p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              All content on the Employo AI platform, including
                              text, software, images, and data, is the property
                              of Employo AI or its licensors. You are granted a
                              limited license to use the Service, but may not
                              copy, modify, or distribute any part of the
                              Service without prior consent.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>Trademarks</p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              The Employo AI name, logo, and all related
                              trademarks are the property of Employo AI. You are
                              not granted any license to use these marks without
                              express permission.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>Privacy</p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              Use of the Employo AI Service is governed by our
                              Privacy Policy, which is available on our Website.
                              By using the Service, you agree to the terms of
                              the Privacy Policy.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>
                            Cancellation Policy
                          </p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              Cancellations of subscriptions or services must be
                              made within 24 hours of placing an order. No
                              cancellations are allowed after services have been
                              delivered or initiated. Special offers or
                              discounts are not eligible for cancellations.
                            </p>
                          </ol>
                        </li>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>Refund Policy</p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              Refunds will only be processed before the dispatch
                              of products or disclosure of online access.
                              Refunds will be processed within 14 business days
                              and credited through the original mode of payment.
                            </p>
                          </ol>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0px" }}>
                            Notice for Copyright Claims
                          </p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              Employo AI complies with copyright laws and will
                              remove any infringing content if notified. Please
                              provide necessary details, including a description
                              of the copyrighted work and the location of the
                              infringing content. An electronic or physical
                              signature of the copyright owner or the person
                              authorized to act on behalf of the owner of the
                              copyright interest.
                            </p>
                            <p style={{ marginBottom: "0px" }}>
                              (a) A description of the copyrighted work that you
                              claim has been infringed.
                            </p>
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              (b) A description of where the material that you
                              claim is infringing is located on the Service,
                              with enough detail that we may find it on the
                              Service.
                            </p>
                            <p style={{ marginBottom: "0px" }}>
                              (c) Your address, telephone number, and email
                              address.
                            </p>
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              (d) A statement by you that you have a good faith
                              belief that the disputed use is not authorized by
                              the copyright owner, its agent, or the law.
                            </p>
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              (e) A statement by you, made under penalty of
                              perjury, that the above information in your notice
                              is accurate and that you are the copyright owner
                              or are authorized to act on the copyright owner's
                              behalf.
                            </p>
                          </ol>
                        </li>
                        <p className="text-justify">
                          Please consult your legal counsel for further details
                          or see Section 51 of The Copyright Act, 1957. Employo
                          AI’s Agent for Notice of claims of copyright
                          infringement can be reached as follows: By email:
                          support@employo.co.in
                        </p>
                        <li style={{ marginBottom: "15px" }}>
                          <p style={{ marginBottom: "0px" }}>
                            Governing Law and Arbitration
                          </p>
                          <ol className="fw-normal">
                            <p style={{ marginBottom: "0px" }}>
                              These Terms are governed by the laws of India, and
                              disputes will be settled in the courts of Punjab.
                            </p>
                          </ol>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0px" }}>Miscellaneous</p>
                          <ol className="fw-normal">
                            <p
                              style={{ marginBottom: "0px" }}
                              className="text-justify"
                            >
                              These Terms constitute the entire agreement
                              between you and Employo AI regarding your use of
                              the Service. If any provision is found
                              unenforceable, the remainder of the Terms will
                              remain in effect. A waiver by Employo AI or you of
                              any provision of these Terms and Conditions or any
                              breach thereof, in any one instance, will not
                              waive such term or condition or any subsequent
                              breach thereof. Employo AI may assign its rights
                              or obligations under these Terms and Conditions
                              without condition. These Terms and Conditions will
                              be binding upon and will inure to the benefit of
                              Employo AI and you, and Employo AI's and your
                              respective successors and permitted assigns .
                            </p>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layouts>
    </>
  );
};

export default TermsAndConditions;
