import DataTable from "react-data-table-component";
import DashboardLayouts from "../../../components/DashboardLayouts";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../Request";
import { JobAppliedThunkUpdate } from "../../../lib/thunk/jobPostThunk";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const Candidates = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};
  const location = useLocation();
  const { state } = location;
  const dashboardCardData = state || [];

  const ExpandedComponent = ({ data }) => (
    <div className="card rounded-0">
      <div className="card-body">
        <p className="card-text mb-2 d-flex gap-2">
          <span>Name :</span>
          <p style={{ fontSize: "14px", fontWeight: "400" }} className="mb-0">
            {data.Name}
          </p>
        </p>
        <p className="card-text mb-2 d-flex gap-2">
          <span>Email :</span>
          <p style={{ fontSize: "14px", fontWeight: "400" }} className="mb-0">
            {data.Email}
          </p>
        </p>
        <p className="card-text mb-2 d-flex gap-2">
          <span>Job Applied For :</span>
          <p style={{ fontSize: "14px", fontWeight: "400" }} className="mb-0">
            {data.Job_Applied_For}
          </p>
        </p>
        <p className="card-text mb-0">
          <span>Note :</span>
          <p style={{ fontSize: "14px", fontWeight: "400" }} className="mb-0">
            {data.Note ? data.Note : "N/A"}
          </p>
        </p>
      </div>
    </div>
  );

  const fetchData = async () => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/all_candidates?email=${newuser.email}`,
        requestOptions
      );
      const result = await response.json();
      const scores = Array.from(result?.candidates);
      const candidates = scores.flatMap((item) => {
        return item?.scores?.map((subItem) => {
          return {
            job_id: item?.job_id,
            ...subItem,
          };
        });
      });
      if (location.state) {
        setData(dashboardCardData);
      } else {
        setData(candidates);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // End loading
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getBadge = (status) => {
    switch (status) {
      case "Invited to Interview":
        return "success";
      case "Shortlisted":
        return "secondary";
      case "Not Interested":
        return "danger";
      case "Confirmed Interview":
        return "success";
      default:
        return "primary";
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.Name,
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      width: "300px",
    },
    {
      name: "Job Applied For",
      selector: (row) => row.Job_Applied_For,
    },
    {
      name: "Status",
      width: "200px",
      cell: (row) => (
        <button
          className={`bg-${getBadge(
            row.Status
          )} text-white border-0 rounded-1 py-1 px-2 text-decoration-none`}
        >
          {row.Status}
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="candidates">
        <DashboardLayouts>
          <div className="job-post-text mx-3 my-3">
            <h3 className="fw-semibold">Candidates List</h3>
          </div>
          <DataTable
            columns={columns}
            data={data}
            pagination={true}
            progressPending={loading}
            responsive={true}
            highlightOnHover={true}
            striped={true}
            expandableRows
            expandableRowDisabled={(row) => row.disabled}
            expandableRowsComponent={ExpandedComponent}
            progressComponent={
              <div className="loader">
                <div className="ring"></div>
                <span>loading...</span>
              </div>
            }
          />
        </DashboardLayouts>
      </div>
    </>
  );
};
export default Candidates;
