import { Link, useLocation, useNavigate } from "react-router-dom";
import profile from "../../assets/images/profile-image.png";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { googleLogout } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { userAction } from "../../lib/slice/userSlice";

const DashboardHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const capitalize = (s) => {
    return s
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const storedUser = localStorage.getItem("user");
  const googleToken = localStorage.getItem("googleToken");
  const userData = localStorage.getItem("userData");
  const newuserName = userData ? JSON.parse(userData) : {};

  const newuser = storedUser ? JSON.parse(storedUser) : {};

  const navigate = useNavigate();

  useEffect(() => {
    if (!newuser.token && !googleToken) {
      dispatch(userAction.resetUser());
      navigate("/");
    }
  }, [newuser]);

  const logOut = () => {
    // localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("googleToken");
    localStorage.removeItem("user");
    localStorage.removeItem("userData");
    googleLogout();
    toast.success("You have successfully logged out!");
  };
  return (
    <>
      <nav className="nav-section navbar navbar-expand-lg bg-white border-bottom border-light py-2 position-sticky top-0 z-1">
        <div className="container">
          <a className="navbar-brand ms-2" href="#">
            {capitalize(splitLocation[1])}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <form className="position-relative" role="search">
                <input
                  className="form-control ps-5 shadow-none py-2 border-0 bg-body-tertiary"
                  type="search"
                  placeholder="Search here..."
                  aria-label="Search"
                  style={{ fontSize: "14px" }}
                />
                <span
                  className="material-symbols-outlined position-absolute px-3"
                  style={{ color: "#5d5fef", fontSize: "18px", top: "10px" }}
                >
                  search
                </span>
              </form>
            </ul>
            <div className="dropdown">
              <a
                className="nav-item nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                href="/dashboard"
              >
                <img
                  src={
                    newuserName.picture ||
                    profile ||
                    "https://via.placeholder.com/150"
                  }
                  className="avatar me-2 rounded-circle"
                  alt="Avatar"
                />
                <span>{newuserName.name || newuser.name}</span>
              </a>
              <ul
                className={`${
                  newuserName.name
                    ? "dropdown-menu rounded-0 start-0"
                    : "dropdown-menu rounded-0 44"
                }`}
              >
                <a href="#" className="dropdown-item">
                  Profile
                </a>
                <Link to="#" className="dropdown-item" onClick={logOut}>
                  Logout
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default DashboardHeader;
