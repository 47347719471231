import { useEffect, useState } from "react";
import DashboardLayouts from "../../components/DashboardLayouts";
import DataTable from "react-data-table-component";
import { baseUrl } from "../../Request";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UpcomingsMeetings } from "../../components/UpcomingsMeetings";
import { CardDasboard } from "../../components/counerDashboard/CardDasboard";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const navigate = useNavigate();
  const [newJob, setNewJobs] = useState([]);
  const [dataCandidates, setCandidates] = useState([]);
  const [openTab, setOpenTab] = useState("Jobs");
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Job Title",
      selector: (row) => row.job_title,
    },
    {
      name: "Experience Level",
      selector: (row) => row.experience_level,
    },
    {
      name: "Schedule",
      selector: (row) => row.schedule,
    },
    {
      name: "Timeline",
      selector: (row) => row.timeline,
    },
    {
      name: "Working Type",
      selector: (row) => row.working_type,
    },
  ];

  const getBadge = (status) => {
    switch (status) {
      case "Invited to Interview":
        return "success";
      case "Shortlisted":
        return "secondary";
      case "Not Interested":
        return "danger";
      default:
        return "primary";
    }
  };

  const columnsNew = [
    {
      name: "Name",
      selector: (row) => row.Name,
      width: "200px",
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      width: "250px",
    },
    {
      name: "Job Applied For",
      selector: (row) => row.Job_Applied_For,
      width: "200px",
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          className={`bg-${getBadge(row.Status)} text-white border-0 rounded-1`}
        >
          {row.Status}
        </button>
      ),
      width: "200px",
    },
  ];
  const tabs = [
    {
      name: "Jobs",
      link: "#",
      content: (
        <DataTable
          columns={columns}
          data={newJob}
          pagination={true}
          progressPending={loading}
          responsive={true}
          highlightOnHover={true}
          striped={true}
        />
      ),
    },
    {
      name: "Candidates",
      link: "#",
      content: (
        <div>
          <div>
            <DataTable
              columns={columnsNew}
              data={dataCandidates}
              pagination={true}
              progressPending={loading}
              responsive={true}
              highlightOnHover={true}
              striped={true}
            />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${baseUrl}/get_all_jobs?email=${newuser.email}`, requestOptions)
      .then((response) => response.json())
      .then((result) => setNewJobs(result.jobs || []))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get("message");
    const id = urlParams.get("id");
    const userData = {
      message: message,
      id: id,
    };
    if (userData.id !== null) {
      const formdata = new FormData();
      formdata.append("id", userData.id);
      formdata.append("email", newuser.email);
      formdata.append("platform", "google_meet");

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseUrl}/update_or_add_email`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          toast.success(result.message);
        })
        .catch((error) => console.error(error));
    }
  }, [navigate]);

  // Candidates

  const fetchDataNew = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/all_candidates?email=${newuser.email}`,
        requestOptions
      );
      const result = await response.json();
      const scores = Array.from(result?.candidates);
      const candidates = scores.flatMap((item) => {
        return item?.scores?.map((subItem) => {
          return {
            job_id: item?.job_id,
            ...subItem,
          };
        });
      });

      setCandidates(candidates);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataNew();
  }, []);

  return (
    <>
      <DashboardLayouts>
        {loading ? (
          <div className="loader">
            <div className="ring"></div>
            <span>loading...</span>
          </div>
        ) : (
          <div className="dashboard container-fluid">
            <div className="row">
              <div className="col-sm-6 col-md-8 col-lg-8">
                <div className="container-fluid">
                  <CardDasboard />
                  <div>
                    <div>
                      <ul className="nav nav-underline">
                        {tabs.map((tab, index) => (
                          <li key={index} className="nav-item">
                            <a
                              href={tab.link}
                              onClick={() => setOpenTab(tab.name)}
                              className={`nav-link ${
                                openTab === tab.name ? "active" : ""
                              }`}
                            >
                              {tab.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      {tabs.map((tab, index) => (
                        <div
                          key={index}
                          className={
                            tab.name === openTab ? "d-block" : "d-none"
                          }
                        >
                          {tab.content}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 shadow rounded-4 bg-white border">
                <UpcomingsMeetings />
              </div>
            </div>
          </div>
        )}
      </DashboardLayouts>
    </>
  );
};

export default Dashboard;
