import { useEffect, useState } from "react";
import Select from "react-select";
import { Country, State } from "country-state-city";
import DashboardLayouts from "../../../components/DashboardLayouts";
import {
  experienceLevels,
  JobTitleTabs,
  optionsBenefits,
  optionsCompensation,
  shiftsSchedule,
  timelineTabs,
} from "../jobpost/data";
import PostLineOne from "../../../assets/images/post-job-line.png";
import PostLineTwo from "../../../assets/images/post-job-Lines.png";
import { getEditMangerJobsID } from "../../../api/getJobPost";
import { useLocation, useNavigate } from "react-router-dom";
import { updateJobPostAPIThunk } from "../../../lib/thunk/jobPostThunk";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { jobAction } from "../../../lib/slice/jobSlice";

const EditManagerJobs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const manageJobsID = location.state;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getuserEmail = localStorage.getItem("user");
  const newuser = getuserEmail ? JSON.parse(getuserEmail) : {};

  const [formData, setFormData] = useState({
    job_id: "",
    title: "",
    workingType: "",
    country: "",
    state: "",
    experience: "",
    schedule: "",
    timeline: "",
    minAmount: "",
    maxAmount: "",
    compensation: [],
    benefits: [],
    description: "",
    positions: "",
    email: newuser.email,
    files: [],
  });
  const [step, setstep] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const nextStep = () => {
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };
  const handleSelect = (type, label) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [type]: prevState[type] === label ? "" : label,
      };
    });
  };
  const fetchDataHandler = async () => {
    try {
      const response = await getEditMangerJobsID(manageJobsID);
      setLoading(false);
      const fetchedData = response.data;
      if (fetchedData) {
        setFormData({
          title: fetchedData.job_title || "",
          workingType: fetchedData.working_type || "",
          country: fetchedData.country || "",
          state: fetchedData.state || "",
          experience: fetchedData.experience_level || "",
          schedule: fetchedData.schedule || "",
          timeline: fetchedData.timeline || "",
          minAmount: fetchedData.salary_min || "",
          maxAmount: fetchedData.salary_max || "",
          jobID: fetchedData.job_id || "",
          compensation: fetchedData.compensation || [],
          benefits: fetchedData.benefits || [],
          description: fetchedData.job_description || "",
          positions: fetchedData.no_of_positions || "",
          email: fetchedData.email || "",
          files: [], // Initially empty
          resume_count: fetchedData.resume_count || 0,
        });
        const countryOption = Country.getAllCountries().find(
          (country) => country.name === fetchedData.country
        );
        const stateOptions = State.getStatesOfCountry(countryOption?.isoCode);
        const stateOption = stateOptions.find(
          (state) => state.name === fetchedData.state
        );
        setSelectedCountry(countryOption);
        setSelectedState(stateOption);
      }
    } catch (error) {
      console.log("!!! analytics card", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataHandler(1);
  }, []);

  const handleCountryChange = (option) => {
    setSelectedCountry(option);
    setSelectedState(null);
    setFormData((prev) => ({
      ...prev,
      country: option.name,
      state: "", // Reset state value
    }));
  };

  const handleStateChange = (option) => {
    setSelectedState(option);
    setFormData((prev) => ({
      ...prev,
      state: option.name,
    }));
  };

  const handleSelectCompensation = (label) => {
    setFormData((prevData) => {
      const isSelected = prevData.compensation.includes(label);

      const compensation = isSelected
        ? prevData.compensation.filter((comp) => comp !== label) // Deselect
        : [...prevData.compensation, label]; // Select

      return { ...prevData, compensation };
    });
  };

  const handleSelectBenefits = (label) => {
    setFormData((prevData) => {
      const isSelected = prevData.benefits.includes(label);

      const benefits = isSelected
        ? prevData.benefits.filter((comp) => comp !== label) // Deselect
        : [...prevData.benefits, label]; // Select

      return { ...prevData, benefits };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData, "formData");

    try {
      const response = await dispatch(updateJobPostAPIThunk(formData));
      console.log(response, "response");
      if (response.payload.message == "Job posting updated successfully!") {
        navigate("/manage-jobs");
        toast.success(response.payload.message);
      }
    } catch (error) {
      console.log("Error updating job:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(jobAction.resetJobs());
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFormData((prevData) => ({
      ...prevData,
      files: files,
      resume_count: files.length,
    }));
  };

  switch (step) {
    case 1:
      return (
        <>
          <DashboardLayouts>
            <div className="job-post-section mb-5">
              <div className="job-post-container">
                <div className="job-post-text mt-3 mb-4">
                  <h2 className="fw-semibold">Edit Manage Jobs</h2>
                </div>
                <div className="mb-5">
                  <img
                    src={PostLineOne}
                    alt="PostLineOne"
                    className="img-fluid"
                  />
                </div>
                <div className="post-form">
                  <form>
                    <div className="mb-4">
                      <label className="form-label">
                        Job Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none py-2 mt-2 form-border-color mb-2"
                        name="title"
                        value={formData.title}
                        onChange={(e) =>
                          setFormData({ ...formData, title: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Working type <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {JobTitleTabs.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelect("workingType", tab.label)
                            }
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.workingType.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="row">
                        <div className="employees-select col">
                          <label className="form-label">
                            Country <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={Country.getAllCountries()}
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            getOptionLabel={(options) => options.name}
                            getOptionValue={(options) => options.name}
                            name="country"
                            className="form-control shadow-none form-border-color p-0 mt-1"
                          />
                        </div>
                        <div className="employees-select col">
                          <label className="form-label">
                            State <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={
                              State?.getStatesOfCountry(
                                selectedCountry?.isoCode
                              ) || []
                            }
                            value={selectedState}
                            onChange={handleStateChange}
                            getOptionLabel={(options) => options.name}
                            getOptionValue={(options) => options.name}
                            name="state"
                            className="form-control shadow-none form-border-color p-0 mt-1"
                            isDisabled={!selectedCountry} // Disable state select if no country is selected
                          />
                        </div>
                        <div className="col">
                          <label className="form-label">
                            No of positions to fill
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control shadow-none py-2 form-border-color"
                            name="positions"
                            value={formData.positions}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                positions: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Experience level <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {experienceLevels.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleSelect("experience", tab.label)
                            }
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.experience.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Schedule <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {shiftsSchedule.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() => handleSelect("schedule", tab.label)}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.schedule.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4 d-flex justify-content-end">
                      <button
                        type="button"
                        className="continue-outline py-2 px-3 d-flex justify-content-center align-items-center gap-2"
                        onClick={nextStep}
                        disabled={
                          formData.title === "" ||
                          formData.workingType === "" ||
                          formData.country === "" ||
                          formData.state === "" ||
                          formData.positions === "" ||
                          formData.experience === "" ||
                          formData.schedule === ""
                        }
                      >
                        Continue
                        <span className="material-symbols-outlined">
                          arrow_right_alt
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DashboardLayouts>
        </>
      );
  }

  switch (step) {
    case 2:
      return (
        <>
          <DashboardLayouts>
            {loading ? (
              <div className="loader">
                <div className="ring"></div>
                <span>loading...</span>
              </div>
            ) : (
              <div className="job-post-section mb-5">
                <div className="job-post-container">
                  <div className="job-post-text mt-3 mb-4">
                    <h2 className="fw-semibold">Post Job</h2>
                  </div>
                  <div className="mb-5">
                    <img
                      src={PostLineTwo}
                      alt="PostLine"
                      className="img-fluid"
                    />
                  </div>
                  <div className="post-form">
                    <div className="mb-4">
                      <label className="form-label">
                        Hiring timeline for the job
                        <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {timelineTabs.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() => handleSelect("timeline", tab.label)}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.timeline.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="row">
                        <div className="col">
                          <label className="form-label">
                            Min Amount <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control shadow-none py-2 mt-2 form-border-color ps-4"
                              name="minAmount"
                              placeholder="₹  5000"
                              value={formData.minAmount}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  minAmount: e.target.value,
                                })
                              }
                            />
                            {formData.minAmount.length > 0 && (
                              <span className="position-absolute top-0 py-2 px-2">
                                ₹
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <label className="form-label">
                            Max Amount <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control shadow-none py-2 mt-2 form-border-color ps-4"
                              name="maxAmount"
                              placeholder="₹  15000"
                              value={formData.maxAmount}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  maxAmount: e.target.value,
                                })
                              }
                            />
                            {formData.maxAmount.length > 0 && (
                              <span className="position-absolute top-0 py-2 px-2">
                                ₹
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Compensation <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {optionsCompensation.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() => handleSelectCompensation(tab.label)} // Call the function on click
                            style={{ cursor: "pointer" }}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.compensation.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Benefits <span className="text-danger">*</span>
                      </label>
                      <div className="post-working-type mt-2">
                        {optionsBenefits.map((tab, index) => (
                          <div
                            key={index}
                            onClick={() => handleSelectBenefits(tab.label)} // Call the function on click
                            style={{ cursor: "pointer" }}
                            className={`border badge text-wrap d-inline-flex align-items-center justify-content-center py-2 px-3 rounded-pill me-2 mb-2 fw-medium ${
                              formData.benefits.includes(tab.label)
                                ? "active"
                                : "text-dark"
                            }`}
                          >
                            <span className="material-symbols-outlined">
                              add
                            </span>
                            {tab.label}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mb-4">
                      <label className="form-label">
                        Job description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        type="text"
                        className="form-control shadow-none py-2 mt-2 form-border-color"
                        name="description"
                        rows="7"
                        value={formData.description}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="mb-4 mt-2 upload-btn-wrapper form-control shadow-none text-center">
                      <label className="form-label fw-semibold">
                        <span className="material-symbols-outlined fs-1 d-flex justify-content-center">
                          cloud_upload
                        </span>
                        {formData.files.length > 0 ? (
                          <ul style={{ padding: 0 }}>
                            {formData.files[0].name}
                            {formData.files.length > 1
                              ? ` + ${formData.files.length - 1} others`
                              : ""}
                          </ul>
                        ) : (
                          <p>
                            {formData.resume_count > 0
                              ? `${formData.resume_count} files already uploaded`
                              : "Upload files (Resume or CSV)"}
                          </p>
                        )}
                      </label>
                      <input
                        type="file"
                        multiple
                        accept=".csv,.pdf,.docx"
                        onChange={handleFileChange}
                        name="files"
                      />
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                      <button
                        type="button"
                        className="back-outline d-flex align-items-center py-2 px-3"
                        onClick={prevStep}
                      >
                        <span className="material-symbols-outlined">
                          arrow_back_ios
                        </span>
                        Back
                      </button>

                      <button
                        type="submit"
                        className="continue-outline py-2 px-3 d-flex justify-content-center align-items-center gap-2"
                        onClick={handleSubmit}
                        disabled={
                          formData.timeline === "" ||
                          formData.minAmount === "" ||
                          formData.maxAmount === "" ||
                          formData.compensation === "" ||
                          formData.benefits === "" ||
                          formData.description === ""
                        }
                      >
                        Continue
                        <span className="material-symbols-outlined">
                          arrow_right_alt
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </DashboardLayouts>
        </>
      );
  }
};
export default EditManagerJobs;
