import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <nav>
      <ul className="pagination justify-content-end mt-4">
        <li className="page-item">
          <Link
            className="page-link rounded-0 shadow-none"
            onClick={goToPrevPage}
            to="#"
          >
            <span className="material-symbols-outlined fs-6">
              arrow_back_ios
            </span>
          </Link>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`page-item ${currentPage === pgNumber ? "active" : ""} `}
          >
            <Link
              onClick={() => setCurrentPage(pgNumber)}
              className="page-link rounded-0 shadow-none"
              to="#"
            >
              {pgNumber}
            </Link>
          </li>
        ))}
        <li className="page-item">
          <Link
            className="page-link rounded-0 shadow-none"
            onClick={goToNextPage}
            to="#"
          >
            <span className="material-symbols-outlined fs-6">
              arrow_forward_ios
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
