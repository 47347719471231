import DataTable from "react-data-table-component";
import DashboardLayouts from "../../../components/DashboardLayouts";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../Request";
import {
  JobAppliedThunk,
  JobAppliedThunkUpdate,
} from "../../../lib/thunk/jobPostThunk";
import { useDispatch } from "react-redux";

const Candidates = () => {
  const getJobId = localStorage.getItem("jobID");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const ExpandedComponent = ({ data }) => (
    <div className="card rounded-0">
      <div className="card-body">
        <p className="card-text mb-2 d-flex gap-2">
          <span>Name :</span>
          <p style={{ fontSize: "14px", fontWeight: "400" }} className="mb-0">
            {data.Name}
          </p>
        </p>
        <p className="card-text mb-2 d-flex gap-2">
          <span>Email :</span>
          <p style={{ fontSize: "14px", fontWeight: "400" }} className="mb-0">
            {data.Email}
          </p>
        </p>
        <p className="card-text mb-2 d-flex gap-2">
          <span>Job Applied For :</span>
          <p style={{ fontSize: "14px", fontWeight: "400" }} className="mb-0">
            {data.Job_Applied_For}
          </p>
        </p>
        <p className="card-text mb-0">
          <span>Note :</span>
          <p style={{ fontSize: "14px", fontWeight: "400" }} className="mb-0">
            {data.Note ? data.Note : "N/A"}
          </p>
        </p>
      </div>
    </div>
  );

  const fetchData = async () => {
    if (getJobId !== null) {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${baseUrl}/all_candidates?jobid=${getJobId}`,
          requestOptions
        );
        const result = await response.json();
        setData(Object.values(result));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // End loading
      }
    }
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  const getBadge = (status) => {
    switch (status) {
      case "Invited to Interview":
        return "success";
      case "Shortlisted":
        return "secondary";
      case "Not Interested":
        return "danger";
      default:
        return "primary";
    }
  };

  const JobApplied = (selectedJob, row, status) => {
    const dataJob = {
      selectedJob,
      row,
      status,
      jobid: getJobId,
    };

    dispatch(JobAppliedThunkUpdate(dataJob));
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.Name,
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      width: "300px",
    },
    {
      name: "Job Applied For",
      selector: (row) => row.Job_Applied_For,
    },
    {
      name: "Status",
      width: "200px",
      cell: (row) => (
        <div className="dropdown">
          <a
            className={`bg-${getBadge(
              row.Status
            )} text-white border-0 rounded-1 dropdown-toggle py-1 px-2 text-decoration-none`}
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {row.Status}
          </a>

          <ul className="dropdown-menu">
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => JobApplied("Shortlisted", row, "1")}
              >
                Shortlisted
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => JobApplied("Not Interested", row, "2")}
              >
                Not Interested
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => JobApplied("Invited to Interview", row, "3")}
              >
                Invited to Interview
              </a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="candidates">
        <DashboardLayouts>
          <DataTable
            columns={columns}
            data={data}
            pagination={true}
            progressPending={loading}
            responsive={true}
            highlightOnHover={true}
            striped={true}
            expandableRows
            expandableRowDisabled={(row) => row.disabled}
            expandableRowsComponent={ExpandedComponent}
            progressComponent={
              <div className="loader">
                <div className="ring"></div>
                <span>loading...</span>
              </div>
            }
          />
        </DashboardLayouts>
      </div>
    </>
  );
};
export default Candidates;
