import Slider from "react-slick";
import TestimonialsCard from "../../../components/TestimonialsCard";
import { useRef } from "react";
import { clientsData } from "../../../utils/data";
import { Element } from "react-scroll";

const Testimonials = () => {
  const slider = useRef();

  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Element name="Reviews">
        <div className="overflow-hidden position-relative testimonials">
          <button
            onClick={previous}
            style={{ top: "40%", marginLeft: "5rem" }}
            className="position-absolute z-1 border-0 bg-testimonals text-white rounded-circle d-flex align-items-center justify-content-center py-2 px-2"
          >
            <span className="material-symbols-outlined">chevron_left</span>
          </button>
          <Slider ref={(c) => (slider.current = c)} {...settings}>
            {clientsData.map((item, index) => (
              <TestimonialsCard
                key={index}
                logo={item.img}
                reviews={item.reviews}
                ClientsName={item.ClientsName}
                position={item.position}
              />
            ))}
          </Slider>
          <button
            onClick={next}
            style={{ top: "40%", marginRight: "5rem" }}
            className="position-absolute end-0 z-1 border-0 bg-testimonals text-white rounded-circle d-flex align-items-center justify-content-center py-2 px-2"
          >
            <span className="material-symbols-outlined">chevron_right</span>
          </button>
        </div>
      </Element>
    </>
  );
};
export default Testimonials;
