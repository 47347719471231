import React from "react";
import Layouts from "../../../components/Layouts";

const AcceptableUsePolicy = () => {
  return (
    <>
      <Layouts>
        <section className="section">
          <div className="container">
            <div className="row">
              <div id="primary" className="content-area   mx-auto">
                <div id="content" className="site-content" role="main">
                  <article
                    id="post-108"
                    className="post-108 page type-page status-publish hentry p-5
                    "
                  >
                    <header className="entry-header">
                      <h1 className="entry-title fw-bold mb-3">
                        Employo AI - Acceptable Use Policy
                      </h1>
                    </header>

                    <div className="entry-content">
                      <p className="text-justify">
                        Employo AI provides a platform to enhance and streamline
                        the hiring process for employers. To ensure a seamless
                        experience for all users, you agree to use the Employo
                        AI service in a manner consistent with the following
                        Acceptable Use Policy.
                      </p>
                      <ol>
                        <p style={{ marginBottom: "0px" }}>
                          You agree not to post or share content that:
                        </p>
                        <ol className="fw-normal">
                          <li className="text-justify">
                            Is sexually explicit, pornographic, or otherwise
                            inappropriate.
                          </li>
                          <li className="text-justify">
                            Creates a risk of harm, injury, emotional distress,
                            or any form of danger to yourself, others, or any
                            entity
                          </li>
                          <li>
                            Exploits or harms children, including by exposing
                            them to inappropriate content or asking for
                            personally identifiable information
                          </li>
                          <li>
                            Violates any laws or regulations, or encourages
                            conduct that would violate them
                          </li>
                          <li>
                            Is considered harmful, abusive, hateful, racist,
                            violent, defamatory, harassing, invasive of privacy,
                            or objectionable
                          </li>
                          <li>
                            Contains illegal information, such as insider
                            trading knowledge or trade secrets
                          </li>
                          <li>
                            Infringes on third-party intellectual property
                            rights, privacy, or other proprietary rights
                          </li>
                          <li>
                            Contains content you are not legally permitted to
                            share or that misleads or deceives others.
                          </li>
                        </ol>
                      </ol>
                      <ol>
                        <p style={{ marginBottom: "0px" }}>
                          You agree not to post or share content that:
                        </p>
                        <ol className="fw-normal">
                          <li>
                            Use, display, mirror, or frame the Employo AI
                            platform or any of its elements (including
                            trademarks or logos) without explicit written
                            consent from Employo
                          </li>
                          <li>
                            Access non-public areas of the service, systems, or
                            technical delivery methods without permission
                          </li>
                          <li>
                            Attempt to scan or probe vulnerabilities in
                            Employo's systems or security
                          </li>
                          <li>
                            Circumvent, remove, or bypass technological
                            protections employed by Employo or third parties
                          </li>
                          <li>
                            Scrape, access, or download user or Employo content
                            without authorization, using any form of automated
                            tools
                          </li>
                          <li>
                            Send unsolicited or unauthorized advertising,
                            promotional materials, or spam through the Employo
                            AI platform
                          </li>
                          <li>
                            Use any hidden tags or metadata associated with
                            Employo’s trademarks without prior written approval
                          </li>
                          <li>
                            Use the platform for commercial purposes or to
                            benefit a third party unless expressly permitted
                          </li>
                          <li>
                            Attempt to impersonate or misrepresent your
                            affiliation with Employo AI, other users, or any
                            entity
                          </li>
                          <li>
                            Reverse engineer, decompile, or disassemble any
                            software utilized in providing Employo's services
                          </li>
                          <li>
                            Interfere with or disrupt access to the platform by
                            others, including sending viruses, overloading, or
                            spamming
                          </li>
                          <li>
                            Collect or store personal information from other
                            users without their explicit consent
                          </li>
                          <li>
                            Violate any applicable local, national, or
                            international laws or regulations
                          </li>
                          <li>
                            Share your account credentials with anyone else for
                            unauthorized access
                          </li>
                        </ol>
                      </ol>
                      <ol>
                        <p style={{ marginBottom: "0px" }}>
                          Consequences of Violations
                        </p>
                        <p className="fw-normal">
                          Employo AI reserves the right to remove any content
                          posted by users that it deems in violation of this
                          Acceptable Use Policy or its Terms of Service. Employo
                          AI may also suspend or terminate accounts at its sole
                          discretion without prior notice or liability, in the
                          event of a violation of this policy or the Terms of
                          Service.
                        </p>
                      </ol>
                      <p>Last revised on October 10, 2024.</p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layouts>
    </>
  );
};

export default AcceptableUsePolicy;
